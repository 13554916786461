import styled from '@emotion/styled';
import shallow from 'zustand/shallow';

import { useIsAuthenticated } from '@updater/auth';
import {
  theme as UdsTheme,
  Card as UdsCard,
  ThemeOverride,
  ThemeProvider,
} from '@updater/ui-uds';
import { ErrorBoundary, Tracker, GoogleTagManager } from 'services';
import { getMicrositeConfigFromCode } from 'config/microsites';
import { useQual, useStore } from 'hooks';
import { MicrositeContext } from 'context';
import { useEffect } from 'react';
import { Layout, Head, OffersContextWrapper, QualErrorModal } from 'components';
import { Fullstory } from 'services/fullstory/Fullstory';

const Card = styled(UdsCard)`
  display: grid;
  place-items: center;
  gap: ${({ theme }) => `${theme.space.s}px`};
  padding: ${({ theme }) => `${theme.space.l}px`};
  box-shadow: 0px 1px 16px -14px black;
  margin: ${({ theme }) => `${theme.space.s}px`};
`;

const Container = styled.div`
  display: grid;
  place-items: center;
  height: 500px;
  max-height: 50vh;
  border: ${({ theme }) => theme.colors.border};
`;

const NoProvider = () => {
  return (
    <Container>
      <Card>No provider in query</Card>
    </Container>
  );
};

export function ProviderWrapper({ children }) {
  const { microsite, provider, uuid } = useQual();
  const [reskinProvider] = useStore((store) => [store.reskinProvider], shallow);
  // We need certain values in order for the shop and buyflows
  // to work correctly.  If any critical values are missing from
  // the qual JSON, throw an error immediately
  let error;
  useEffect(() => {
    console.log('[ProviderApp] appVersion:', process.env.NEXT_PUBLIC_GIT_SHA);
  }, []);
  // TODO: the original qual object in Shop App had a key called
  // `provider`, which we used at launch to determine what microsite
  // configuration should be loaded.  The nomenclature doesn't make
  // sense though, because for multi-provider instances, we need some
  // key that means "load this microsite configuration" and `provider`
  // is really confusing in that context.
  // We've added a new key `microsite` to the qual coming from the WP
  // sites, but it's going to be a slow rollout.  Until we have confirmation
  // that all microsites are sending the `microsite` key, do a cascading
  // check to determine what config to load:
  let micrositeConfigKey;
  if (microsite) {
    micrositeConfigKey = microsite;
    console.log(`[ProviderApp] loading ${micrositeConfigKey} via 'microsite'`);
  } else if (provider) {
    micrositeConfigKey = provider;
    console.log(`[ProviderApp] Loading ${micrositeConfigKey} via 'provider'`);
  } else {
    console.log('NO PROVIDER (microsite and provider keys missing');
    error = 'NO_PROVIDER';
  }
  let theme = UdsTheme;
  const micrositeConfig =
    micrositeConfigKey && getMicrositeConfigFromCode(micrositeConfigKey);
  if (!micrositeConfig) {
    console.log(`Missing microsite config (key=${micrositeConfigKey})`);
    error = `NO_CONFIG_${micrositeConfigKey}`;
  } else {
    const { defaultTheme } = micrositeConfig;
    theme = defaultTheme;
  }
  if (!uuid) {
    error = `NO_UUID`;
  }

  // Theme overrides: if the user is on a single-provider site (e.g. xfinity)
  // and they are *NOT* eligible for that provider, we can sell them other
  // offers from providers of the same type (MSO or Telco).  We reskin the whole
  // experience with the new provider when this happens, but the microsite
  // config, the promo ID and the rest of the data remain the same.  This
  // is a visual reskin only!
  let overrideTitle;
  if (reskinProvider) {
    console.log(
      `[ProviderWrapper] overriding theme with ${reskinProvider.reskinTheme?.customTheme?.name} reskin`
    );
    theme = reskinProvider.reskinTheme;
    overrideTitle = reskinProvider.reskinTitle;
  }

  const isAuthenticated = useIsAuthenticated();

  return (
    <MicrositeContext.Provider value={micrositeConfig}>
      <Fullstory>
        <ThemeProvider customTheme={theme as ThemeOverride} selfHostedFonts>
          {!error && (
            <Tracker>
              <Head overrideTitle={overrideTitle} />
              <GoogleTagManager />
              <OffersContextWrapper>
                <Layout>
                  <ErrorBoundary>
                    {!isAuthenticated ? null : !micrositeConfigKey ? (
                      <NoProvider />
                    ) : (
                      children
                    )}
                  </ErrorBoundary>
                </Layout>
              </OffersContextWrapper>
            </Tracker>
          )}
          {error && <QualErrorModal reason={error} />}
        </ThemeProvider>
      </Fullstory>
    </MicrositeContext.Provider>
  );
}
