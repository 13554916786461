import React from 'react';

import { H2, Text, Box } from '@updater/ui-uds';
import { useFormNav } from '@updater/ui-informant';

export const ScreenHeading: React.FC = () => {
  const { currentScreenConfig } = useFormNav();
  if (currentScreenConfig) {
    return (
      <Box mt="xs">
        <H2 as="h2" variant={['xxl', null, 'xxxl', null, null]} mb="20px">
          {currentScreenConfig.heading}
        </H2>
        {currentScreenConfig.subHeading && (
          <Text variant={['m']} style={{ whiteSpace: 'pre-line' }} mb="28px">
            {currentScreenConfig.subHeading}
          </Text>
        )}
      </Box>
    );
  }
  return null;
};
