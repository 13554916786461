import { SuperSchema } from '@updater/ui-informant';

export const devicesSchema: SuperSchema = {
  if: {
    properties: {
      serviceLines: {
        contains: { const: 'internet' },
      },
    },
  },
  then: {
    properties: {
      devices: {
        type: 'string',
        oneOf: [
          {
            title: '1-5',
            const: 'devices_1_5',
          },
          {
            title: '6-11',
            const: 'devices_6_11',
          },
          {
            title: '12+',
            const: 'devices_12',
          },
        ],
        ui: {
          label: 'Paid move-in costs',
          subHeading: null,
          component: 'radiolist',
          wrapperComponent: 'CheckListWrapper',
          options: [
            {
              label: '1-5',
              id: 'devices_1_5',
              value: 'devices_1_5',
            },
            {
              label: '6-11',
              id: 'devices_6_11',
              value: 'devices_6_11',
            },
            {
              label: '12+',
              id: 'devices_12',
              value: 'devices_12',
            },
          ],
          componentProps: {
            controlSize: 'l',
          },
        },
        default: 'devices_1_5',
      },
    },
    required: ['devices'],
  },
};
