import React from 'react';
import {
  Palette,
  Text,
  Flex,
  SpaceProps,
  TextVariantKey,
} from '@updater/ui-uds';

function numberToPrice(n: number, zeroLabel = 'Free'): string {
  if (n === 0) {
    return zeroLabel;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(n);
}

type LayoutProps = { variant: 'l' | 'm' | 'mBold' | 's' } & SpaceProps;

type PriceProps = {
  amount: number;
  term?: 'mo';
  termColor?: Palette;
  zeroLabel?: string;
} & LayoutProps;

type Variant = {
  priceVariant: TextVariantKey;
  termVariant: TextVariantKey;
};

export function Price({
  amount,
  term,
  variant,
  termColor,
  zeroLabel,
  ...flexProps
}: PriceProps) {
  const variantMap: Record<string, Variant> = {
    l: {
      priceVariant: 'xxxlBold',
      termVariant: 'xxl',
    },
    m: {
      priceVariant: 'm',
      termVariant: 'm',
    },
    mBold: {
      priceVariant: 'mBold',
      termVariant: 'mBold',
    },
    s: {
      priceVariant: 's',
      termVariant: 's',
    },
  };

  const { priceVariant, termVariant } = variantMap[variant];

  return (
    <Flex data-cy="priceDetails" alignItems="center" {...flexProps}>
      <Text variant={priceVariant}>{numberToPrice(amount, zeroLabel)}</Text>
      {term && (
        <Text variant={termVariant} color={termColor}>
          {!!amount && `/${term}`}
        </Text>
      )}
    </Flex>
  );
}
