import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
  Comcast,
  Cox,
  Spectrum,
  Mediacom,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
} from 'config/providers';
import { VerizonTheme } from 'config/themes';

export const VerizonDeals: SingleProviderConfig = {
  name: 'VerizonDeals',
  micrositeCode: 'Verizon',
  mainProvider: Verizon,
  shopType: ShopType.SINGLE_PROVIDER,
  // shopType: ShopType.SYNTHETIC,
  domain: 'verizondeals.com',
  homepage: 'https://verizondeals.com',
  title: 'Verizon Authorized Dealer',
  phoneNumber: '855-272-8393',
  leadModePhoneNumber: '877-649-1240',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: VerizonTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'Verizon',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    Comcast,
    Cox,
    Spectrum,
    Mediacom,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
  ],
};
