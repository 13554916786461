import { MultiProviderConfig, ShopType, CheckoutType } from 'types';
import { allLeadMode } from 'config';
import { ProviderConfigList } from 'config/providers';
import { TurbotenantTheme } from 'config/themes';

export const Turbotenant: MultiProviderConfig = {
  name: 'Turbotenant',
  micrositeCode: 'Turbotenant',
  shopType: ShopType.MULTI_PROVIDER,
  domain: 'updater.com',
  homepage: 'https://www.turbotenant.com',
  title: 'TurboTenant Internet Shopping',
  phoneNumber: '877-657-5508',
  leadModePhoneNumber: '877-657-5508',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: TurbotenantTheme,
  needsDcSession: false,
  hasUpdaterCobranding: true,
  isIFramed: false,
  updaterCrossSellActive: false,
  source: 'TurboTenant',
  noOffersRetry: 2,
  providers: ProviderConfigList,
  getCheckoutType: allLeadMode,
};
