import { useState, useEffect, useContext, useRef } from 'react';
import { Text } from '@updater/ui-uds';
import styled from '@emotion/styled';
import { OffersContext, MicrositeContext } from 'context';
import { ShopType, SingleProviderConfig } from 'types';
import { Container } from './Container';
import { ProgressBar } from './ProgressBar';

const Wrapper = styled.div`
  margin: 0 auto;
  padding: ${({ theme }) => theme.space.xxxxl}px;
  text-align: center;
`;

function useInterval(callback, delay) {
  const savedCallback = useRef(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export const LoadingDisplay = () => {
  const [counter, setCounter] = useState(0);
  const micrositeConfig = useContext(MicrositeContext);
  const { shopType, updaterCrossSellActive } = micrositeConfig;
  const { primaryData, secondaryData, tertiaryData } =
    useContext(OffersContext);

  const updaterCrossSellLoading =
    shopType === ShopType.SINGLE_PROVIDER &&
    updaterCrossSellActive &&
    primaryData.loading === false &&
    primaryData.availableOffers?.length === 0 &&
    secondaryData.loading === false &&
    secondaryData.availableOffers?.length === 0 &&
    tertiaryData.loading === true;

  let mainText = 'Looking for the best packages in your area...';
  let subText = 'Almost there...';
  if (shopType === ShopType.SINGLE_PROVIDER && updaterCrossSellLoading) {
    const { mainProvider } = micrositeConfig as SingleProviderConfig;
    mainText = `${mainProvider.name} is not available at your address...`;
    subText =
      "We're searching our wider network for available offers at your address, please wait...";
  }

  useInterval(() => {
    setCounter(counter + 6.25);
    if (counter >= 100) {
      setCounter(100);
    }
  }, 2000);

  return (
    <Wrapper>
      <Text data-testid="header" as="h1" marginTop="xxxl" variant="xxxlBold">
        {mainText}
      </Text>
      <Text data-testid="subheader" variant="xl" marginTop="m" marginBottom="s">
        {subText}
      </Text>
      <Container>
        <ProgressBar width={counter} />
      </Container>
    </Wrapper>
  );
};
