import { useShallow } from 'zustand/react/shallow';
import styled from '@emotion/styled';
import { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Flex,
  Image,
  useTheme,
  ButtonLink,
} from '@updater/ui-uds';
import { useStore, useQual } from 'hooks';
import { useTracking } from 'react-tracking';
import {
  ShopType,
  Address,
  CrossSellInput,
  CrossSellType,
  SingleProviderConfig,
  SiteMode,
} from 'types';
import { OffersContext, MicrositeContext } from 'context';
import { Spinner } from '@phosphor-icons/react';
import {
  getTopCrossSellProvider,
  getProviderLogoFromS12y,
} from 'config/providers';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`;
const ServiceMessage = styled(Text)`
  max-width: 500px;
  text-align: center;
`;
const LoadingContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  height: 32px;
  .loadingText {
    padding-left: 5px;
  }
`;
const NavigatingContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 32px;
`;

// NOTE: the Cross Selling modal experience should only appear
// for single providers.
export function CrossSellModal() {
  const offersContext = useContext(OffersContext);
  const micrositeConfig = useContext(MicrositeContext);
  const {
    shopType,
    homepage,
    mainProvider,
    crossSellProviders,
    getCrossSellAction,
  } = micrositeConfig as SingleProviderConfig;
  const { primaryData, secondaryData } = offersContext;
  const { availableOffers, providerSummaries, loading } = secondaryData;
  const { setCrossSellModal, setSiteMode, setReskinProvider, siteMode } =
    useStore(
      useShallow((state) => ({
        setCrossSellModal: state.setCrossSellModal,
        setSiteMode: state.setSiteMode,
        setReskinProvider: state.setReskinProvider,
        siteMode: state.siteMode,
      }))
    );
  const decodedQuery = useQual();
  const [actionTimeout, setActionTimeout] = useState(undefined);
  const theme = useTheme();

  // TODO: do we need to check address suggestions / available here?
  const modalIsOpen =
    siteMode === SiteMode.DEFAULT_MODE &&
    shopType === ShopType.SINGLE_PROVIDER &&
    loading === false &&
    typeof availableOffers !== undefined &&
    availableOffers?.length > 0 &&
    primaryData.availableOffers?.length === 0;

  const topCrossSellProvider = getTopCrossSellProvider(
    crossSellProviders,
    secondaryData.availableProviders
  );
  const serviceable = topCrossSellProvider !== undefined;

  // TODO: we're getting the logo from serviceability for some reason,
  // even though we have other provider logos hard-coded here.
  // We should pick one or the other...
  const crossSellLogo = serviceable
    ? getProviderLogoFromS12y(
        topCrossSellProvider,
        secondaryData.availableProviders
      )
    : undefined;

  // This is special logic only needed for Spectrum.  Because we can't cross-sell
  // Spectrum on an Updater-owned website, we ultimately redirect the users to
  // Spectrum's etail.spectrum.com portal.  We need to pass several identifiers
  // in the query string, including a special identifier TransID, which maps
  // to Falcon sesssion ID used to retrieve Spectrum offers from s12y.  This value
  // is only used for Spectrum cross-sell redirects; other provider redirects will
  // use the DC session ID and send users back into Shop App for now.
  const dcFwsTid =
    topCrossSellProvider && providerSummaries?.length
      ? providerSummaries.find((providerSummary) => {
          return (
            providerSummary.provider.code === topCrossSellProvider.providerCode
          );
        })?.falconIdentifier
      : undefined;

  console.log('[CrossSellModal] dcFwsTid', dcFwsTid);

  const closeModal = useCallback(() => {
    setCrossSellModal(false);
  }, [setCrossSellModal]);

  const { trackEvent } = useTracking({
    object: 'cross_selling_modal',
    details: {
      provider: topCrossSellProvider ? topCrossSellProvider.name : '',
    },
  });

  useEffect(() => {
    if (modalIsOpen) {
      trackEvent({
        verb: 'viewed',
      });
    }
  }, [modalIsOpen, trackEvent]);

  const crossSellAction = useMemo(() => {
    if (serviceable && topCrossSellProvider) {
      const address = {
        street: decodedQuery.street,
        unit: decodedQuery.unit,
        city: decodedQuery.city,
        state: decodedQuery.state,
        postalCode: decodedQuery.zip,
        line2Type: decodedQuery.line2Type,
      } as Address;
      const crossSellInput = {
        providerConfig: topCrossSellProvider,
        dcFwsTid,
        address,
      } as CrossSellInput;
      return getCrossSellAction(crossSellInput);
    }
    return undefined;
  }, [
    decodedQuery,
    topCrossSellProvider,
    serviceable,
    dcFwsTid,
    getCrossSellAction,
  ]);

  const handleCrossSellAction = useMemo(() => {
    if (crossSellAction) {
      if (crossSellAction.crossSellType === CrossSellType.CROSS_SELL_RESKIN) {
        return function () {
          console.log('[CrossSellModal] reskinning...');
          setReskinProvider(crossSellAction.reskinProviderConfig);
          setSiteMode(SiteMode.RESKIN_MODE);
          closeModal();
        };
      } else if (
        crossSellAction.crossSellType === CrossSellType.CROSS_SELL_REDIRECT
      ) {
        if (crossSellAction.crossSellUrl) {
          return function () {
            console.log(
              `[CrossSellModal] redirecting ${crossSellAction.crossSellUrl}`
            );
            window.location.assign(crossSellAction.crossSellUrl);
          };
        }
        console.log(`[CrossSellModal] no crossSellUrl found`);
      } else {
        console.log(
          `[CrossSellModal] unhandled crossSellType ${crossSellAction.crossSellType}`
        );
      }
    }
    return undefined;
  }, [crossSellAction]);

  useEffect(() => {
    if (modalIsOpen && serviceable && topCrossSellProvider && !actionTimeout) {
      const timeout = setTimeout(() => {
        if (handleCrossSellAction !== undefined) {
          handleCrossSellAction();
        } else {
          console.log(`[CrossSellModal] no crossSellAction handler, closing`);
          closeModal();
        }
        clearTimeout(actionTimeout);
      }, 3000);
      setActionTimeout(timeout);
    }
  }, [
    modalIsOpen,
    serviceable,
    topCrossSellProvider,
    handleCrossSellAction,
    actionTimeout,
    closeModal,
  ]);

  const displayName = mainProvider ? mainProvider.displayName : '';

  return (
    <Modal isOpen={modalIsOpen} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="736" minWidth="1" minHeight="1">
        <ModalBody padding="l" marginBottom="xxxxl">
          <Container>
            <Text
              as="h1"
              variant={['mBold', null, 'xlBold', null, null]}
              marginBottom="l"
              textAlign="center"
            >
              {displayName} is not available at your address.
            </Text>
            <ServiceMessage variant="m" marginBottom="l">
              We found services available in your area from the following
              provider(s):
            </ServiceMessage>
            {!serviceable && (
              <ButtonLink
                href={homepage}
                marginTop="l"
                dataCy="back-home-button"
                target="_top"
                size="l"
              >
                Return to Homepage
              </ButtonLink>
            )}
            {serviceable && (
              <Flex
                flexDirection="row"
                justifyContent="space-around"
                gap={`${theme.space.s}px`}
              >
                {topCrossSellProvider && (
                  <Flex flexDirection="column" alignItems="center">
                    <Image
                      key={topCrossSellProvider.name}
                      src={crossSellLogo.url}
                      alt={topCrossSellProvider.name || 'provider logo'}
                      maxWidth="150px"
                    />
                    <LoadingContainer>
                      <Spinner size={32} color="#333" weight="fill">
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="2s"
                          from="0 0 0"
                          to="360 0 0"
                          repeatCount="indefinite"
                        />
                      </Spinner>
                      <div className="loadingText">
                        Searching for best {topCrossSellProvider.name} offers
                        now, one moment...
                      </div>
                    </LoadingContainer>
                  </Flex>
                )}
              </Flex>
            )}
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
