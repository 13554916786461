import styled from '@emotion/styled';

export const Nav = styled.nav`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'logo . call';
  place-items: center;
  height: 100%;
  padding: 0;
  }
`;
