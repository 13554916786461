import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  Comcast,
  Cox,
  Spectrum,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  Mediacom,
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
} from 'config/providers';
import { TDSTheme } from 'config/themes';

export const TDSAuthorizedOffers: SingleProviderConfig = {
  name: 'TDSAuthorizedOffers',
  micrositeCode: 'tds',
  mainProvider: TDS,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'tdsauthorizedoffers.com',
  homepage: 'https://tdsauthorizedoffers.com',
  title: 'TDS Telecom Authorized Dealer',
  phoneNumber: '877-707-3811',
  leadModePhoneNumber: '877-707-3811',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: TDSTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'TDS',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    Comcast,
    Cox,
    Spectrum,
    Mediacom,
    Optimum,
    Wave,
    Grande,
    RCN,
    BuckeyeBroadband,
    WOW,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    Verizon,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
  ],
};
