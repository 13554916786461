import styled from '@emotion/styled';

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `0 ${theme.space.s}px`};
  margin: 0 auto;

  ${({ theme }) => theme.mediaQueries.xl} {
    max-width: ${({ theme }) => theme.breakpoints[3]};
  }
`;
