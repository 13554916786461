import { ShopType, CheckoutType, MultiProviderConfig } from 'types';
import { allCallMode } from 'config';
import { DishTheme } from 'config/themes';
import { ProviderConfigList } from 'config/providers';

export const MyDishIFramed: MultiProviderConfig = {
  name: 'MyDishIFramed',
  micrositeCode: 'MyDishIFrame',
  shopType: ShopType.MULTI_PROVIDER,
  domain: 'https://partner-cart.updater.com',
  homepage: 'https://my.dish.com/internet',
  title: 'DISH Shop For Internet',
  phoneNumber: '855-554-2339',
  leadModePhoneNumber: '855-554-2339',
  defaultCheckoutType: CheckoutType.CALL_MODE,
  defaultTheme: DishTheme,
  hasUpdaterCobranding: false,
  needsDcSession: false,
  isIFramed: true,
  updaterCrossSellActive: false,
  source: 'MyDishIFrame',
  noOffersRetry: 2,
  getCheckoutType: allCallMode,
  providers: ProviderConfigList,
};
