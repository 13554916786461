import { SingleProviderConfig, ShopType, CheckoutType } from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  Comcast,
  Cox,
  Spectrum,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  Mediacom,
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
} from 'config/providers';
import { XfinityTheme } from 'config/themes';

// Origin - https://updater.atlassian.net/browse/UPA-51
export const CordlessXfinityPartner: SingleProviderConfig = {
  name: 'CordlessXfinityPartner',
  micrositeCode: 'xfinitypartner',
  mainProvider: Comcast,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'xfinityspecial.com',
  homepage: 'https://xfinityspecial.com',
  title: 'Xfinity Authorized Dealer',
  phoneNumber: '844-571-1132',
  leadModePhoneNumber: '844-571-1132',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: XfinityTheme,
  needsDcSession: true,
  isIFramed: false,
  source: 'Xfinity',
  noOffersRetry: 2,
  commercialHomepage: 'https://comcastbusinessspecial.com',
  getCrossSellAction: getDefaultCrossSellAction,
  crossSellProviders: [
    Cox,
    Spectrum,
    Mediacom,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    Verizon,
  ],
  guestBuyflowUrls: {
    development: 'http://bridgevine.io:3000/v2/checkout',
    staging: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    qa: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    production: 'https://cart.xfinityspecial.com/v2/checkout',
  },
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.xfinityspecial.com',
  },
  getCheckoutType: allLeadMode,
};
