/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';

import { Flex, Text, GridLayout, GridLayoutColumn } from '@updater/ui-uds';
import { OffersContext, MicrositeContext } from 'context';
import { useQual } from 'hooks';
import { ShopType } from 'types';
import { PageContent } from '../PageContent';

export const Content = styled.div`
  margin-top: auto;
  border-top: 1px solid #cccccc;
  display: grid;
  padding: 10px 0;
  width: 100%;
  place-items: start;
`;

const Identifier = styled.div`
  color: #bbb;
  font-size: 12px;
`;

export function InnerFooter() {
  const offersContext = useContext(OffersContext);
  const micrositeConfig = useContext(MicrositeContext);
  const { primaryData } = offersContext;
  const { uuid } = useQual();

  // We want to display the following data in the footer:
  //   * uuid (all sites)
  //   * serviceability requestId (all sites)
  //   * Falcon TID (single-provider sites only)
  const dcFwsTid = useMemo(() => {
    if (micrositeConfig.shopType === ShopType.SINGLE_PROVIDER) {
      return primaryData.providerSummaries?.length
        ? primaryData.providerSummaries[0].falconIdentifier
        : undefined;
    }
    return undefined;
  }, [micrositeConfig, primaryData]);

  const requestId = useMemo(() => {
    return primaryData.requestId;
  }, [primaryData]);

  const Lock = () => {
    return (
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5393 6.51022H10.1077L10.106 4.32178C10.1042 1.93778 8.19802 -0.00177656 5.85498 1.22114e-06C3.51194 0.001779 1.60571 1.94133 1.60746 4.32533L1.61095 6.51378H1.4572C0.651721 6.51556 -0.00174374 7.18045 3.49566e-06 8V14.5156C3.49566e-06 15.3351 0.655216 16 1.46069 16L10.5428 15.9947C11.3483 15.9947 12.0017 15.3298 12 14.5102V7.99289C12 7.17333 11.3448 6.51022 10.5393 6.51022ZM3.17647 6.51378L3.17298 4.32533C3.17298 2.81956 4.37507 1.59467 5.85498 1.59289C7.33489 1.59289 8.53873 2.81778 8.53873 4.32178L8.54222 6.51022L3.17647 6.51378Z"
          fill="#01314A"
        />
      </svg>
    );
  };

  return (
    <PageContent>
      <Content>
        <GridLayout
          columns="12"
          gridGap="m"
          marginTop="m"
          includeMargin={false}
          width="100%"
        >
          <GridLayoutColumn span={['12', null, '8', null, null]} spanStart="1">
            <Flex alignItems="center" justifyContent="left">
              <Lock />
              <Text color="#01314A" variant="sBold" marginLeft="8px">
                SECURE CONNECTION
              </Text>
              <Text color="#01314A" variant="xs" marginLeft="xs">
                All data is encrypted and protected through a secured
                connection.
              </Text>
            </Flex>
          </GridLayoutColumn>
          <GridLayoutColumn
            span={['12', null, '4', null, null]}
            spanStart={['1', null, '9', null, null]}
          >
            <Flex
              alignItems="flex-end"
              justifyContent="right"
              flexDirection="column"
            >
              {uuid && <Identifier>UUID: {uuid}</Identifier>}
              {requestId && <Identifier>s12y: {requestId}</Identifier>}
              {dcFwsTid && (
                <Identifier>
                  TID:{' '}
                  <Text as="span" data-cy="falcon-tid" data-testid="falcon-tid">
                    {dcFwsTid}
                  </Text>
                </Identifier>
              )}
            </Flex>
          </GridLayoutColumn>
        </GridLayout>
      </Content>
    </PageContent>
  );
}
