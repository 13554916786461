import { ReactNode } from 'react';
import { useSpring, config } from '@react-spring/web';
import { Text, useTheme } from '@updater/ui-uds';

import { Container } from './Container';
import { Card } from './Card';

export interface ErrorDisplayProps {
  children?: ReactNode;
}

export function ErrorDisplay({ children }: ErrorDisplayProps) {
  const theme = useTheme();
  const spring = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,20px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0,0)' },
    config: (prop) => ({ ...config.gentle, clamp: prop === 'opacity' }),
    reset: true,
  });
  let childrenOrError;
  if (!children) {
    childrenOrError = <Text color={theme.colors.error}>An error occured</Text>;
  } else {
    childrenOrError = children;
  }

  return (
    <Container style={spring}>
      <Card>{childrenOrError}</Card>
    </Container>
  );
}
