import styled from '@emotion/styled';
import { usePhoneNumber } from 'hooks';
import { TextLink, TextVariantKey, Button } from '@updater/ui-uds';
import { Events, useTracking } from 'services';

export function looksLikePhoneNumber(s) {
  const asString = String(s);
  const matches = asString.match(/\d/g);
  return matches !== null && matches.length === 10;
}

export enum CallRailComponentType {
  TEXT_LINK = 0,
  BUTTON = 1,
}

// Note: this component is kind of nutty; we may want to refactor
// into two separate components (one Button, one TextLink) that have
// their own input signatures.  There's a significant amount of overlap
// though and making the business logic DRY across multiple files
// and components would be less readable IMHO.

type PhoneProps = {
  text: string;
  componentType?: CallRailComponentType;
  callback?: () => void;
  variant?: TextVariantKey;
  buttonSize?: 's' | 'm' | 'l';
  isLeadMode?: boolean;
  icon?: any;
  clickEventName?: Events;
  textColor?: string;
};

export function CallRailPhone({
  componentType = CallRailComponentType.TEXT_LINK,
  callback = undefined,
  text = '###',
  variant = 'xl',
  buttonSize = 'l',
  isLeadMode = false,
  icon = undefined, // what's the type for Phosphor icons...?
  clickEventName = Events.CALL_BUTTON_GENERIC,
  textColor = undefined,
}: PhoneProps) {
  const phoneNumber = usePhoneNumber({ isLeadMode });

  const { trackEvent } = useTracking();

  const handleClick = () => {
    if (typeof callback === 'function') {
      callback();
    }
    trackEvent(clickEventName, 'clicked');
    // For users on mobile devices, or edge-case users that use
    // their desktops to make phone calls, we need to make sure
    // this number is the CallRail swap number.  CallRail's swap.js
    // will scan the DOM and replace numbers on the page automatically,
    // but this is a functional call so we need to manually replace
    // it using their SDK
    if (window && window.CallTrk) {
      window.CallTrk.getSwapNumbers([phoneNumber], (swapDictionary) => {
        // The swap dictionary comes back in a weird format where
        // the object keys are the swap number as an integer...
        // {
        //   8661235019: "8667801998",
        // }
        // Even worse, we store the phoneNumber in XXX-XXX-XXXX format :(
        // Convert this to something we can more easily read rather
        // than parseInt on the phone number?  either is ugly.
        const swapPair = Object.entries(swapDictionary).find((pair) => {
          return (
            pair[0].toString() === phoneNumber.toString().replace(/[^\d]/g, '')
          );
        });
        const swapNumber = swapPair !== undefined ? swapPair[1] : '';
        if (looksLikePhoneNumber(swapNumber)) {
          console.log(`[CR] Calling ${swapNumber}`);
          // NOTE: you cannot use window.open here; too many users have
          // adblockers
          window.location.assign(`tel:${swapNumber}`);
        } else {
          console.log(`[CR_FAIL] Calling ${phoneNumber}`);
          window.location.assign(`tel:${phoneNumber}`);
        }
      });
    } else {
      console.log(`[NO_CR] Calling ${phoneNumber}`);
      window.location.assign(`tel:${phoneNumber}`);
    }
  };

  // This is a somewhat hacky DSL!  But we really need to consolidate the
  // business logic with these phone numbers into a single component...
  const index = text.indexOf('###');
  let preText = '';
  let postText = '';
  let finalText;
  if (index !== -1) {
    preText = text.substr(0, index);
    postText = text.substr(index + 3);
    finalText = (
      <>
        {preText}{' '}
        <span
          data-cy="google-phone-number"
          data-testid="google-phone-number"
          className="google-phone-number"
        >
          {phoneNumber}
        </span>{' '}
        {postText}
      </>
    );
  } else {
    finalText = text;
  }

  if (componentType === CallRailComponentType.BUTTON) {
    let fontSize;
    if (buttonSize === 'm') {
      fontSize = '16px';
    } else if (buttonSize === 'l') {
      fontSize = '24px';
    } else {
      fontSize = 'inherit';
    }
    const styles = {
      fontSize,
    };
    const styledText = <span style={styles}>{finalText}</span>;
    return (
      <Button
        onClick={handleClick}
        dataCy="phone-number-button"
        data-testid="phone-number-button"
        size={buttonSize}
        leftIcon={icon}
      >
        {styledText}
      </Button>
    );
  }
  let StyledTextLink = TextLink;
  if (textColor) {
    StyledTextLink = styled(TextLink)`
      color: ${({ theme }) => theme.customTheme.colors.headerText} !important;
    `;
  }
  return (
    <StyledTextLink onClick={handleClick} variant={variant}>
      {icon}
      {finalText}
    </StyledTextLink>
  );
}
