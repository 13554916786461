import styled from '@emotion/styled';
import { useState, useEffect, useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Image,
} from '@updater/ui-uds';
import { useQual, useUpdaterCrossSellUrl } from 'hooks';
import { useTracking } from 'react-tracking';
import { ShopType, SingleProviderConfig } from 'types';
import { OffersContext, MicrositeContext } from 'context';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`;
const ServiceMessage = styled(Text)`
  max-width: 500px;
  text-align: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  img {
    margin: 0 10px;
    display: block;
  }
`;
const RedirectMessage = styled.div`
  display: block;
  font-size: 16px;
`;

// NOTE: the Cross Selling modal experience should only appear
// for single providers.
export function UpdaterCrossSellModal() {
  const offersContext = useContext(OffersContext);
  const micrositeConfig = useContext(MicrositeContext);
  const { shopType, mainProvider, updaterCrossSellProviders } =
    micrositeConfig as SingleProviderConfig;
  const updaterCrossSellUrl = useUpdaterCrossSellUrl();
  const { primaryData, secondaryData, tertiaryData } = offersContext;
  const decodedQuery = useQual();
  const [loadingIndex, setLoadingIndex] = useState(3);

  // The boolean logic for the updater.com/internet cross-sell is
  // straightforward but requires a lot of checks:
  // (1) no primary offers
  // (2) no secondary offers
  // (3) at least 1 tertiary offer
  const modalIsOpen =
    shopType === ShopType.SINGLE_PROVIDER &&
    updaterCrossSellProviders &&
    updaterCrossSellProviders?.length > 0 &&
    primaryData.loading === false &&
    primaryData.availableOffers?.length === 0 &&
    secondaryData.loading === false &&
    secondaryData.availableOffers?.length === 0 &&
    tertiaryData.loading === false &&
    tertiaryData.availableOffers?.length > 0;

  function closeModal() {}

  const { trackEvent } = useTracking({
    object: 'updater_cross_sell_modal',
    details: {
      // provider: topCrossSellProvider ? topCrossSellProvider.name : '',
    },
  });

  useEffect(() => {
    if (modalIsOpen) {
      trackEvent({
        verb: 'viewed',
      });
    }
  }, [modalIsOpen, trackEvent]);

  let mutatedQual = { ...decodedQuery };
  // The only difference between the two quals should be the
  // provider (microsite) key to load Updater.com/internet and
  // the GTM code.
  // The promoID should be the promoID of the origin site, not
  // the UCI promoID.
  // Use the `microsite` keyword and delete the `provider` key.
  // Sites that haven't moved from the old Shop App config may
  // contain two and the microsite key will take precedence.
  mutatedQual = Object.assign(mutatedQual, {
    microsite: 'Updater',
    gtmCode: 'GTM-M356222',
  });
  delete mutatedQual.provider;

  let redirectMessage;
  if (modalIsOpen) {
    redirectMessage = `Taking you to Updater shopping in ${loadingIndex}...`;
    if (loadingIndex > 0) {
      setTimeout(() => {
        setLoadingIndex(loadingIndex - 1);
      }, 1000);
    }
  }

  if (modalIsOpen && loadingIndex === 0) {
    console.log('redirecting...');
    console.log(mutatedQual);
    const encodedQual = btoa(JSON.stringify(mutatedQual));
    window.location.assign(
      `${updaterCrossSellUrl}/qualify?qual=${encodedQual}&uuid=${mutatedQual.uuid}&skipToPlans=true`
    );
  }

  const displayName = mainProvider ? mainProvider.displayName : '';

  return (
    <Modal isOpen={modalIsOpen} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="736" minWidth="1" minHeight="1">
        <ModalBody padding="l" marginBottom="xxxxl">
          <Container>
            <Text
              as="h1"
              variant={['mBold', null, 'xlBold', null, null]}
              marginBottom="l"
              textAlign="center"
            >
              {displayName} is not available in your area but the following
              provider(s) are!
            </Text>
            <LogoContainer>
              {tertiaryData.availableProviders?.map((s12yProvider, idx) => {
                return (
                  <Image
                    key={`uci_logo_${idx}`}
                    src={s12yProvider.logo.url}
                    alt={s12yProvider.name}
                    maxWidth="150px"
                  />
                );
              })}
            </LogoContainer>
            <ServiceMessage variant="m" marginBottom="l">
              Enter Updater, the easiest place to shop and compare internet
              plans all in one place.
            </ServiceMessage>
            <RedirectMessage>{redirectMessage}</RedirectMessage>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
