import styled from '@emotion/styled';
import { NextButton as InformantNextButton } from '@updater/ui-informant';

export const NextButton = styled(InformantNextButton)`
  margin-top: 40px;

  ${({ theme }) => theme.mediaQueries.m} {
    max-width: 312px;
  }
`;
