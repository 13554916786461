import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  ATT,
  Brightspeed,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
  //Earthlink,
} from 'config/providers';
import { ATTTheme } from 'config/themes';

// Origin - https://updater.atlassian.net/browse/TVI-1215
export const CordlessATTPartner: SingleProviderConfig = {
  name: 'CordlessATTPartner',
  micrositeCode: 'cordlessattpartner',
  mainProvider: ATT,
  shopType: ShopType.SINGLE_PROVIDER,
  // shopType: ShopType.SYNTHETIC,
  domain: 'attspecial.com',
  homepage: 'https://attspecial.com',
  title: 'AT&T Authorized Dealer',
  phoneNumber: '844-710-5188 ',
  leadModePhoneNumber: '844-647-7953',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: ATTTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'ATT',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    Verizon,
    Brightspeed,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    CenturyLink,
    CincinnatiBell,
    Frontier,
    Windstream,
  ],

  guestBuyflowUrls: {
    development:
      'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    staging: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    qa: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    production: 'https://cart.attspecial.com/v2/checkout',
  },
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.attspecial.com',
  },
};
