import styled from '@emotion/styled';
import { Box } from '@updater/ui-uds';

export const NoVisibleScrollBox = styled(Box)`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;
