import styled from '@emotion/styled';

export const ContinueContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.s}px;
  flex-direction: column-reverse;

  ${({ theme }) => theme.mediaQueries.m} {
    flex-direction: row;
  }
`;
