import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  Comcast,
  Cox,
  Spectrum,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  Mediacom,
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Earthlink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
} from 'config/providers';
import { EarthlinkTheme } from 'config/themes';

export const BuyEarthlink: SingleProviderConfig = {
  name: 'BuyEarthlink',
  micrositeCode: 'earthlink',
  mainProvider: Earthlink,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'buy.earthlink.com',
  homepage: 'https://buy.earthlink.com',
  title: 'Earthlink Authorized Dealer',
  phoneNumber: '866-674-8115',
  leadModePhoneNumber: '866-676-1191',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: EarthlinkTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'Earthlink',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    ATT,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Windstream,
    CincinnatiBell,
    Verizon,
  ],
  updaterCrossSellActive: false,
  updaterCrossSellProviders: [
    Comcast,
    Cox,
    Spectrum,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
};
