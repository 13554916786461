import { MultiProviderConfig, ShopType, CheckoutType } from 'types';
import { allLeadMode } from 'config';
import { ProviderConfigList } from 'config/providers';
import { ApartmentListTheme } from 'config/themes';

export const ApartmentList: MultiProviderConfig = {
  name: 'ApartmentList',
  micrositeCode: 'ApartmentList',
  shopType: ShopType.MULTI_PROVIDER,
  domain: 'updater.com',
  homepage: 'https://updater.com/internet/apartment-list',
  title: 'Apartment list Internet Shopping',
  phoneNumber: '877-657-5508',
  leadModePhoneNumber: '877-657-5508',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: ApartmentListTheme,
  needsDcSession: false,
  hasUpdaterCobranding: true,
  isIFramed: false,
  updaterCrossSellActive: false,
  source: 'ApartmentList',
  noOffersRetry: 2,
  providers: ProviderConfigList,
  getCheckoutType: allLeadMode,
};
