import { Box, IconButton } from '@updater/ui-uds';
import { useRouter } from 'next/router';
import { ArrowLeft } from '@phosphor-icons/react';

type BackButtonProps = {
  hide?: boolean;
};

export function BackButton({ hide }: BackButtonProps) {
  const router = useRouter();

  const handleBack = async () => {
    router.back();
  };
  return (
    <Box>
      {!hide && (
        <IconButton
          data-testid="back_button"
          variant="secondary"
          size="s"
          icon={<ArrowLeft />}
          onClick={handleBack}
        />
      )}
    </Box>
  );
}

export default BackButton;
