import { useContext } from 'react';
import { MicrositeContext } from 'context';
import { PageContainer } from './PageContainer';
import { Header } from './Header';
import { Footer } from './Footer';
import { InnerFooter } from './InnerFooter';
import { PageContent } from './PageContent';
import { useTheme } from '@emotion/react';

export function Layout({ children }) {
  const micrositeConfig = useContext(MicrositeContext);
  const theme = useTheme();
  const isIFramed = micrositeConfig && micrositeConfig.isIFramed;

  return (
    <PageContainer>
      {!isIFramed ? <Header /> : null}
      <PageContent data-cy={`theme-${theme.customTheme?.name}`}>
        {children}
      </PageContent>
      <InnerFooter />
      {!isIFramed ? <Footer /> : null}
    </PageContainer>
  );
}
