import { useContext, useMemo } from 'react';
import { OffersContext, MicrositeContext } from 'context';
import { useAddress, useQual, useStore } from 'hooks';
import { useOptionalFieldsById } from 'hooks/useOptionalFieldsById';
import { ShopType } from 'types';
import { useOffers } from '@updater/ui-widgets';
import { AvailableOffersInput } from '@updater/consumer-graph';
import { SessionCookie } from 'services/auth';

export function OffersContextWrapper({ children }) {
  const micrositeConfig = useContext(MicrositeContext);
  const address = useAddress();
  const { optionalFields, promoId, uuid, userEmail } = useQual();
  const optionalFieldsById = useOptionalFieldsById();
  const skipNormalization = useStore((store) => store.skipNormalization);

  // Track page load times
  /*
  const pageLoad = useRef<number | null>(null);
  const prevPage = useRef('prev');
  useEffect(() => {
    if (prevPage.current !== router.asPath) {
      prevPage.current = router.asPath;
      pageLoad.current = new Date().getTime();
    }
    if (!loading && pageLoad.current) {
      tracking.trackEvent(Events.PAGE_LOAD, 'tracked', {
        page: router.asPath,
        loadTime: new Date().getTime() - pageLoad.current,
      });
      pageLoad.current = null;
    }
  }, [router, loading]);
  */

  // IMPORTANT: this is the only place we call serviceability to
  // get available offers, both for this provider (e.g. Xfinity)
  // and for "all providers".  We make both calls right away because
  // we don't know until we've received this provider's offers back
  // whether the address is serviceable or not.  Because the "all providers"
  // call can take much longer -- sometimes up to 30 seconds -- we fire
  // it right away to reduce the wait time for the user once they get to
  // the offers page and the CrossSellModal appears.
  //
  // Note that any change in address will trigger a re-render of this
  // component, forcing BOTH availableOffers calls to refetch automatically.
  // You don't need to manually call refetch anywhere else in the app or
  // in any other component: the OffersContext will pass the data down
  // everywhere its needed.

  // We load offers based on the microsite type definition.  For each type,
  // the three offers queries are different and have different meanings.
  //
  // SINGLE_PROVIDER:
  // Single providers are microsites that are branded as a single provider,
  // e.g. Xfinity or Optimum.  For these microsites, primaryOffers is
  // a s12y query with only that provider in the providers array, e.g.:
  // ['xfinity'].  seconaryOffers is a s12y with all of that provider's
  // cross sell providers, which are defined in the microsite config
  // under the crossSellProviders property.  tertiaryOffers is null.
  //
  // MULTI_PROVIDER:
  // Multi providers are microsites that are provider-agnostic and branded
  // as a third-party, e.g. Updater or ApartmentList.  For these sites,
  // primaryOffers are a list of all the offers that this microsite has
  // available, e.g. ['xfinity', 'cox', 'att', ... ].  seconaryOffers is null
  // and tertiaryOffers is null.
  //
  // SYNTHETIC_PROVIDER: TBD
  //
  // PROVIDER_REDIRECT: TBD
  //

  const baseInputsForUseOffers: AvailableOffersInput = useMemo(
    () => ({
      address: {
        city: address?.city,
        postalCode: address?.postalCode,
        state: address?.state,
        street: address?.street,
        unit: address?.unit,
      },
      optionalFields: {
        gclid: optionalFields?.option5 ?? '',
        uuid: uuid ?? '',
      },
      partner: {
        partnerPropertyId: optionalFieldsById?.partnerPropertyId ?? '',
        user: {
          email: userEmail ?? '',
          uuid: uuid ?? '',
        },
      },
      promoId: promoId ?? '',
      source: micrositeConfig?.source,
      skipAddressNormalization: skipNormalization,
    }),
    [
      address,
      micrositeConfig?.source,
      optionalFields?.option5,
      optionalFieldsById?.partnerPropertyId,
      promoId,
      skipNormalization,
      uuid,
      userEmail,
    ]
  );

  const { primaryProviderList, secondaryProviderList, tertiaryProviderList } =
    useMemo(() => {
      let primaryProviderList = [];
      let secondaryProviderList = [];
      let tertiaryProviderList = [];

      if (
        micrositeConfig.shopType === ShopType.SINGLE_PROVIDER &&
        'mainProvider' in micrositeConfig
      ) {
        const {
          mainProvider,
          crossSellProviders,
          updaterCrossSellActive,
          updaterCrossSellProviders,
        } = micrositeConfig;
        primaryProviderList = new Array(mainProvider.providerCode);
        secondaryProviderList = crossSellProviders?.map(
          (provider) => provider.providerCode
        );
        if (
          updaterCrossSellActive &&
          updaterCrossSellProviders &&
          updaterCrossSellProviders?.length > 0
        ) {
          tertiaryProviderList = updaterCrossSellProviders?.map(
            (provider) => provider.providerCode
          );
        }
      } else if (
        micrositeConfig.shopType === ShopType.MULTI_PROVIDER &&
        'providers' in micrositeConfig
      ) {
        const { providers } = micrositeConfig;
        primaryProviderList = providers.map(
          (provider) => provider.providerCode
        );
      }

      return {
        primaryProviderList,
        secondaryProviderList,
        tertiaryProviderList,
      };
    }, [micrositeConfig, skipNormalization]);

  const sessionCookie = SessionCookie.read();
  const waitForSession = !sessionCookie;
  console.log('[OffersContextWrapper] waitForSession', waitForSession);

  const primaryAvailableOffers = useOffers({
    input: {
      ...baseInputsForUseOffers,
      providerCodes: primaryProviderList,
    },
    queryOptions: {
      skip: waitForSession || primaryProviderList?.length === 0,
    },
  });
  // TODO: do not query if we don't have a provider list
  const secondaryAvailableOffers = useOffers({
    input: {
      ...baseInputsForUseOffers,
      providerCodes: secondaryProviderList,
    },
    queryOptions: {
      skip: waitForSession || secondaryProviderList?.length === 0,
    },
  });
  const tertiaryAvailableOffers = useOffers({
    input: {
      ...baseInputsForUseOffers,
      providerCodes: tertiaryProviderList,
    },
    queryOptions: {
      skip: waitForSession || tertiaryProviderList?.length === 0,
    },
  });

  // Wrap this in a useMemo, other it will force extra re-renders
  // because React considers a POJO as a "new" change.
  const contextData = useMemo(() => {
    // The OffersContext gets two main objects, one for just this provider
    // and the other for all other providers.
    return {
      primaryData: {
        ...primaryAvailableOffers,
      },
      secondaryData: {
        ...secondaryAvailableOffers,
      },
      tertiaryData: {
        ...tertiaryAvailableOffers,
      },
    };
  }, [
    primaryAvailableOffers,
    secondaryAvailableOffers,
    tertiaryAvailableOffers,
  ]);

  return (
    <OffersContext.Provider value={contextData}>
      {children}
    </OffersContext.Provider>
  );
}
