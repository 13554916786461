import styled from '@emotion/styled';

export const Data = styled.td`
  &:first-of-type {
    padding-left: ${({ theme }) => `${theme.space.l}px`};
  }
  &:last-of-type {
    padding-right: ${({ theme }) => `${theme.space.l}px`};
  }
`;
