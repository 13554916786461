import { useRouter } from 'next/router';
import { useTracking as useReactTracking } from 'react-tracking';
import getConfig from 'next/config';
import {
  BackButton,
  Form,
  FormProvider,
  NavEventProps,
  NavProvider,
  ScreenFields,
  useNextRouterNavigator,
} from '@updater/ui-informant';
import { SkipLinkWithDisclaimer } from 'components';
import { layoutSchema, schema, wrapperComponents } from './informant';
import { Content } from './Content';
import { NextButton } from './NextButton';
import { ScreenHeading } from './ScreenHeading';
import { SortMethod, useFilterAndSortOffers } from '@updater/ui-widgets';
import { useCallback, useContext } from 'react';
import { useQual, useStore } from 'hooks';
import { MicrositeContext } from 'context';
import { useShallow } from 'zustand/react/shallow';

export function QuizWrapper() {
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const micrositeConfig = useContext(MicrositeContext);
  const { userFirstName, userLastName, zip, city, state, apartment, street } =
    useQual();
  const navigator = useNextRouterNavigator({
    basePath: publicRuntimeConfig.basePath,
  });
  const { trackEvent: reactTrackEvent } = useReactTracking();
  const [setAcceptedUpdaterTerms] = useStore(
    useShallow((store) => [store.setAcceptedUpdaterTerms])
  );
  const { setProductByPackages } = useFilterAndSortOffers({
    defaultSortMethod: SortMethod.PriceLowToHigh,
    defaultSelections: {
      products: [],
      providers: [],
      additionalLanguages: [],
    },
  });

  const onSubmit = (values, context) => {
    context.setSubmitting(false);
    router.push('/plans');
  };

  const onFirstScreenBack = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  const onChange = useCallback(
    (formData) => {
      if (!setProductByPackages) return;
      const wantsInternet = formData.serviceLines?.includes('internet');
      const wantsTv = formData.serviceLines?.includes('tv');
      setProductByPackages({
        internet: wantsInternet,
        tv: wantsTv,
        phone: false,
      });
    },
    [setProductByPackages]
  );

  const onBeforeNavigate = useCallback(
    ({ fromScreen }: NavEventProps) => {
      // https://updater.atlassian.net/jira/software/c/projects/TVI/boards/541?assignee=60bbd7523aa6050068a5fca0&selectedIssue=TVI-244
      if (fromScreen?.name === 'services') {
        setAcceptedUpdaterTerms(true);
        reactTrackEvent({
          verb: 'accepted',
          object: 'privacy_and_terms_consent',
          details: {
            user_address: {
              street,
              unit: apartment,
              city,
              state,
              zip,
            },
            first_name: userFirstName,
            last_name: userLastName,
          },
        });
      }
    },
    [
      apartment,
      city,
      reactTrackEvent,
      state,
      street,
      userFirstName,
      userLastName,
      zip,
      setAcceptedUpdaterTerms,
    ]
  );

  return (
    <FormProvider
      schema={schema}
      layoutSchema={layoutSchema}
      trackEvent={reactTrackEvent}
      onSubmit={onSubmit}
      onChange={onChange}
      wrapperComponents={wrapperComponents}
    >
      <NavProvider
        navigator={navigator}
        onFirstScreenBack={onFirstScreenBack}
        onBeforeNavigate={onBeforeNavigate}
      >
        <Form>
          <Content>
            {!Boolean(micrositeConfig?.hideFirstPageBackButton) && (
              <BackButton />
            )}
            <ScreenHeading />
            <ScreenFields />
            <NextButton />
          </Content>
        </Form>
        <SkipLinkWithDisclaimer />
      </NavProvider>
    </FormProvider>
  );
}
