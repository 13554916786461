import { SuperSchema } from '@updater/ui-informant';

export const phoneSchema: SuperSchema = {
  if: {
    properties: {
      serviceLines: {
        contains: { const: 'homePhone' },
      },
    },
  },
  then: {
    type: 'object',
    properties: {
      homePhone: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          oneOf: [
            {
              const: 'localAndLongDistanceCalling',
              title: 'Local and long distance calling',
            },
            {
              const: 'InternationalCalling',
              title: 'International calling',
            },
          ],
        },
        ui: {
          component: 'checklist',
          wrapperComponent: 'CheckListWrapper',
          componentProps: {
            options: [
              {
                label: 'Local channels & news',
                value: 'localAndLongDistanceCalling',
              },
              {
                label: 'International calling',
                value: 'InternationalCalling',
              },
            ],
          },
        },
        required: ['oneOf'],
      },
    },
    required: ['homePhone'],
  },
};
