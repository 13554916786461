import { Card as UdsCard } from '@updater/ui-uds';
import styled from '@emotion/styled';

export const Card = styled(UdsCard)`
  display: grid;
  place-items: center;
  gap: ${({ theme }) => `${theme.space.s}px`};
  padding: ${({ theme }) => `${theme.space.l}px`};
  box-shadow: 0px 1px 16px -14px black;
  margin: ${({ theme }) => `${theme.space.s}px`};
`;
