import styled from '@emotion/styled';
import { Palette, Box } from '@updater/ui-uds';

interface ProgressBarProps {
  width: number;
}

export const ProgressBar = styled(Box)<ProgressBarProps>(
  ({ theme, width }) => `
    color: ${Palette.black};
    background-color: ${theme.customTheme.colors.progressBar};
    height: 13px;
    width: ${width}%;
    border-top-left-radius: ${theme.radii.xl};
    border-bottom-left-radius: ${theme.radii.xl};
    border-top-right-radius: ${width === 100 ? theme.radii.xl : 0};
    border-bottom-right-radius: ${width === 100 ? theme.radii.xl : 0};
  `
);
