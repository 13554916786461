import { TvInternetOffer } from '@updater/consumer-graph/';
import { useOfferContext } from '@updater/ui-widgets';

export const useShouldShowHighlights = () => {
  const offer = useOfferContext() as TvInternetOffer;
  const isBasicDummyOffer = Boolean(
    offer?.metadata?.dummy && offer?.metadata?.dummyOfferType === 'basic'
  );

  const hasInternet = Boolean(offer?.products?.internet?.downloadSpeed);
  const showHighlightsOverhaul =
    !isBasicDummyOffer &&
    hasInternet &&
    isHighlightsEnabledForProvider({
      providerCode: offer?.providerCode,
    });

  return { showHighlightsOverhaul };
};

export interface isHighlightsEnabledForProviderArgs {
  providerCode: string;
}

export const HighlightsProviderCodes = [
  'att',
  'xfinity',
  'spectrum',
  'cox',
  'verizon',
  'centurylink',
  'windstream',
  'tds',
  'frontier',
  'mediacom',
  'altafiber',
  'brightspeed',
  'brightspeedv2', //to support two Brightspeed provider set of offers - https://updater.atlassian.net/browse/TVI-1204
  // wave, rcn, grande, and astound are the same - https://upd-home-platform.slack.com/archives/C0711HQ3S81/p1723844606537309
  'wave',
  'rcn',
  'grande',
  'astound',
  'optimum',
];

export const isHighlightsEnabledForProvider = ({
  providerCode,
}: isHighlightsEnabledForProviderArgs) =>
  HighlightsProviderCodes.includes(providerCode);
