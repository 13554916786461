import { Box, Palette, Text } from '@updater/ui-uds';
import {
  PlanCardButton,
  PlanCardButtonProps,
  useOfferContext,
  AttOfferAnnotation,
} from '@updater/ui-widgets';
import { ComponentProps, FC } from 'react';
import { PlanSummary } from './PlanSummary';
import { usePhoneNumber } from 'hooks';
import { TvInternetOffer } from '@updater/consumer-graph';
import { useFeature } from '@growthbook/growthbook-react';
import dynamic from 'next/dynamic';
import { useShouldShowHighlights } from './useShouldShowHighlights';

const PlanCardCollapse = dynamic(() =>
  import('@updater/ui-widgets').then((mod) => mod.PlanCardCollapse)
);

export type PlanCardProps = Partial<ComponentProps<typeof Box>> &
  PlanCardButtonProps;

export const PlanCard: FC<PlanCardProps> = ({
  onClickSelectButton,
  cta,
  ...rest
}) => {
  const offer = useOfferContext() as TvInternetOffer;
  const isBasicDummyOffer = Boolean(
    offer?.metadata?.dummy && offer?.metadata?.dummyOfferType === 'basic'
  );
  const { on: attOfferEnabled } = useFeature('provider_app_att_offer');

  const { showHighlightsOverhaul } = useShouldShowHighlights();

  const shopHotline = usePhoneNumber();
  return (
    <Box
      data-testid="plan-box"
      border={`1px solid ${Palette.gray80}`}
      borderRadius="xs"
      overflow="hidden"
      mb={['s', 's', 's', 'm', 'm']}
      backgroundColor="white"
      {...rest}
    >
      {attOfferEnabled && <AttOfferAnnotation />}
      <Box padding={['s', 's', 's', 'm', 'm']}>
        <PlanSummary data-testid="plan-summary" />
        {isBasicDummyOffer ? (
          <Box>
            <Text variant={'s'} color={Palette.gray40}>
              Check for availability:
            </Text>
            <PlanCardButton
              onClickSelectButton={onClickSelectButton ?? (() => {})}
              cta={{ type: 'call', hotline: shopHotline, displayHotline: true }}
            />
          </Box>
        ) : (
          Boolean(onClickSelectButton) && (
            <PlanCardButton
              cta={cta}
              onClickSelectButton={onClickSelectButton}
            />
          )
        )}
      </Box>
      {showHighlightsOverhaul && <PlanCardCollapse />}
    </Box>
  );
};
