import {
  ServiceabilityProvider,
  TvInternetOffer,
} from '@updater/consumer-graph/';
import { useFeature } from '@growthbook/growthbook-react';
import { OffersWithProviders } from '@updater/ui-widgets';

type ShowcaseRanking = {
  enabled: boolean;
  providerCode: string;
  offerName: string;
};

type OfferWithProvider = {
  offer: TvInternetOffer;
  provider: ServiceabilityProvider;
};

export function useOfferShowcase(
  offers: OffersWithProviders,
  featureKey: string
): OfferWithProvider {
  const showcaseRule = useFeature(featureKey);

  if (!showcaseRule.on) return null;

  return (
    showcaseRule.value
      .filter((ranking: ShowcaseRanking) => ranking.enabled)
      .map((ranking: ShowcaseRanking) =>
        offers.find(
          ({ offer, provider }) =>
            provider.code === ranking.providerCode &&
            offer.name === ranking.offerName
        )
      )
      .find((match: OfferWithProvider) => match) || null
  );
}
