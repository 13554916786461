import styled from '@emotion/styled';
import { useMemo, useEffect, useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Flex,
  Image,
  useTheme,
  ButtonLink,
} from '@updater/ui-uds';
import { useQual } from 'hooks';
import { useTracking } from 'react-tracking';
import {
  ShopType,
  Address,
  CrossSellInput,
  SingleProviderConfig,
  CheckoutType,
} from 'types';
import { OffersContext, MicrositeContext } from 'context';
import { Spinner } from '@phosphor-icons/react';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`;
const LoadingContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  height: 32px;
  .loadingText {
    padding-left: 5px;
  }
`;

// NOTE: the Cross Selling modal experience should only appear
// for single providers.
export function ProviderRedirectModal({
  updatedCheckoutType,
}: {
  updatedCheckoutType: CheckoutType;
}) {
  const offersContext = useContext(OffersContext);
  const micrositeConfig = useContext(MicrositeContext);
  const decodedQuery = useQual();
  const theme = useTheme();
  const { shopType, mainProvider, getProviderRedirectUrl } =
    micrositeConfig as SingleProviderConfig;
  const { primaryData } = offersContext;
  const { loading } = primaryData;

  const modalIsOpen =
    shopType === ShopType.SINGLE_PROVIDER &&
    updatedCheckoutType === CheckoutType.PROVIDER_REDIRECT &&
    loading === false &&
    primaryData.availableOffers?.length > 0;

  const thisProvider =
    primaryData.availableProviders?.length > 0
      ? primaryData.availableProviders[0]
      : undefined;

  const dcFwsTid = primaryData.providerSummaries?.length
    ? primaryData.providerSummaries[0].falconIdentifier
    : undefined;

  // TODO: we're getting the logo from serviceability for some reason,
  // even though we have other provider logos hard-coded here.
  // We should pick one or the other...
  const providerLogo = thisProvider ? thisProvider.logo : undefined;

  function closeModal() {}

  const { trackEvent } = useTracking({
    object: 'provider_redirect_modal',
    details: {
      provider: thisProvider ? thisProvider.name : '',
    },
  });

  useEffect(() => {
    if (modalIsOpen) {
      trackEvent({
        verb: 'viewed',
      });
    }
  }, [modalIsOpen, trackEvent]);

  const holdNavigation = !modalIsOpen || !dcFwsTid;
  if (holdNavigation) {
    if (!dcFwsTid) {
      console.log('[ProviderRedirect] no dcFwsTid');
    }
  }

  const providerRedirectUrl = useMemo(() => {
    if (modalIsOpen && thisProvider) {
      const address = {
        street: decodedQuery.street,
        unit: decodedQuery.unit,
        city: decodedQuery.city,
        state: decodedQuery.state,
        postalCode: decodedQuery.zip,
        line2Type: decodedQuery.line2Type,
      } as Address;
      const providerRedirectUrl = getProviderRedirectUrl({
        providerConfig: thisProvider,
        dcFwsTid,
        uuid: decodedQuery.uuid,
        address,
      } as CrossSellInput);
      console.log(`[ProviderRedirect] redirectUrl ${providerRedirectUrl}`);
      return providerRedirectUrl;
    }
    return undefined;
  }, [decodedQuery, thisProvider, dcFwsTid]);

  const navigating = !holdNavigation && providerRedirectUrl !== undefined;

  if (navigating) {
    // Send the user to the provider's site
    console.log(`[ProviderRedirect] navigating to ${providerRedirectUrl}`);
    // Use a timeout here to accommodate cypress, which gets stuck on navigation
    // events and will fail if this fires before it can validate the modal
    // appearing
    setTimeout(() => {
      window.location.assign(providerRedirectUrl);
    }, 500);
  }

  const displayName = mainProvider ? mainProvider.displayName : '';

  return (
    <Modal isOpen={modalIsOpen} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="736" minWidth="1" minHeight="1">
        <ModalBody padding="l" marginBottom="xxxxl">
          <Container>
            {modalIsOpen && (
              <Flex
                flexDirection="row"
                justifyContent="space-around"
                gap={`${theme.space.s}px`}
              >
                {thisProvider && (
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    data-cy="provider-redirect"
                    data-testid="provider-redirect"
                  >
                    <Image
                      key={displayName}
                      src={providerLogo.url}
                      alt={displayName || 'provider logo'}
                      maxWidth="150px"
                    />
                    <LoadingContainer>
                      <Spinner size={32} color="#333" weight="fill">
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="2s"
                          from="0 0 0"
                          to="360 0 0"
                          repeatCount="indefinite"
                        />
                      </Spinner>
                      <div className="loadingText" data-testid="loadingText">
                        Taking you to {displayName} shopping...
                      </div>
                    </LoadingContainer>
                    {navigating && (
                      <ButtonLink
                        variant="secondary"
                        data-testid="provider-redirect-buttonlink"
                        href={providerRedirectUrl}
                        marginTop="l"
                      >
                        Click here if you are not automatically redirected
                      </ButtonLink>
                    )}
                  </Flex>
                )}
              </Flex>
            )}
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
