/* eslint jsx-a11y/anchor-is-valid: 0 */
import Link from 'next/link';
import styled from '@emotion/styled';

import { PageContent } from '../PageContent';
import { Content } from './Content';

const Container = styled.footer`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.customTheme.colors.footerBackground} !important;
  color: ${({ theme }) => theme.customTheme.colors.footerText};
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: ${({ theme }) => theme.customTheme.colors.footerBorder};
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  min-height: 46px;
  padding: 12px 0;
  position: relative;
  ${({ theme }) =>
    theme.customTheme.colors.footerGradient
      ? `&::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        height: 6px;
        background: linear-gradient(${theme.customTheme.colors.footerGradient});
        content: '';
        }`
      : ''}
`;

const StyledText = styled.span`
  color: ${({ theme }) => theme.customTheme.colors.footerText};
  font-size: 14px;
`;

export function Footer() {
  return (
    <Container>
      <PageContent>
        <Content>
          <StyledText>
            © {new Date().getFullYear()} Updater, Inc. All rights reserved
            <Link href="https://updater.com/uhs-terms/" legacyBehavior>
              <a
                style={{ marginLeft: 5, textDecoration: 'underline' }}
                target="_blank"
              >
                Privacy Policy
              </a>
            </Link>
          </StyledText>
        </Content>
      </PageContent>
    </Container>
  );
}
