import {
  Box,
  GridLayout,
  GridLayoutColumn,
  IconButton,
  TextButton,
  Text,
  Flex,
  useViewportSizes,
} from '@updater/ui-uds';
import styled from '@emotion/styled';
import {
  NoPlansMatchResetFilters,
  ProductTypes,
  ProductTypesUI,
  SortMethod,
  SortOrderUI,
  useFilterAndSortOffers,
} from '@updater/ui-widgets';

import { useRouter } from 'next/router';
import { ArrowLeft, MapPin } from '@phosphor-icons/react';
import { FC, useEffect, useMemo, useRef, useContext } from 'react';

import { useFormContext } from '@updater/ui-informant';
import { OffersContext, MicrositeContext } from 'context';
import { ShopType, CheckoutType, SiteMode } from 'types';
import {
  LoadingDisplay,
  PlanProviderCards,
  CrossSellModal,
  UpdaterCrossSellModal,
  NotServiceableModal,
  AsyncLoadingModal,
  ProviderRedirectModal,
} from 'components';
import { FormikContextType } from 'formik';
import { useStore } from 'hooks';
import { Events, useTracking } from 'services';
import { ServiceabilityProvider } from '@updater/consumer-graph/*';
import { useShallow } from 'zustand/react/shallow';

/** Converts quiz data to default filters. */
export function getDefaultProductFilter({
  hasInternet = false,
  hasTv = false,
  hasPhone = false,
}) {
  if (hasInternet && !hasTv && !hasPhone) {
    return ProductTypes.Internet;
  }
  if (hasInternet && hasTv && !hasPhone) {
    return ProductTypes.InternetTv;
  }
  if (hasInternet && hasTv && hasPhone) {
    return ProductTypes.InternetTvPhone;
  }
  if (hasInternet && !hasTv && hasPhone) {
    return ProductTypes.InternetPhone;
  }
  if (!hasInternet && hasTv && !hasPhone) {
    return ProductTypes.Tv;
  }
  if (!hasInternet && hasTv && hasPhone) {
    return ProductTypes.TvPhone;
  }
  if (!hasInternet && !hasTv && hasPhone) {
    return ProductTypes.Phone;
  }

  return undefined;
}

const AddressContainer = styled(Flex)`
  place-items: center;
`;

/** Main component for Plan Page */
export const PlansPage: FC = () => {
  const offersContext = useContext(OffersContext);
  const micrositeConfig = useContext(MicrositeContext);
  const router = useRouter();
  const tracker = useTracking();
  const [setWantsNewAddress, siteMode] = useStore((store) => [
    store.setWantsNewAddress,
    store.siteMode,
  ]);
  const [setAcceptedUpdaterTerms] = useStore(
    useShallow((store) => [store.setAcceptedUpdaterTerms])
  );
  const { primaryData, secondaryData, tertiaryData } = offersContext;
  const { isLarge, isExtraLarge } = useViewportSizes();

  // Reskin logic: if this is a single provider site, and the primary provider
  // is not available, AND there are offers from another provider of the same
  // type (either MSO or Telco), we reskin provider app to look like the
  // secondary provider.  We also need to show offers from that secondary
  // provider.  When the reskin completes, we also change the site mode.
  // Load our state, including the offers, from either the primaryData payload
  // or the secondaryData payload depending on the SiteMode.  Note:
  // * there's a small chance that multiple providers will have offers in the secondaryData
  //   payload.  This is OK -- the site will look like one of them but we can still
  //   show multiple providers
  // * for multi-provider sites, the siteMode will never be anything but DEFAULT_MODE,
  //   so it is safe to make this check here without explicitly checking the
  //   microsite config
  const dataSource =
    siteMode === SiteMode.DEFAULT_MODE ? primaryData : secondaryData;
  const {
    address,
    loading,
    availableProviders,
    offersWithProviders,
    productTypes,
    requestId,
  } = dataSource;

  const hasOffers = offersWithProviders && offersWithProviders.length > 0;

  const informantForm = useFormContext() as FormikContextType<any>;

  const changeAddressHandler = () => setWantsNewAddress(true);

  const offersLoadTime = useRef(new Date().getTime());
  useEffect(() => {
    if (loading) {
      offersLoadTime.current = new Date().getTime();
    } else {
      const waitTime = new Date().getTime() - offersLoadTime.current;
      tracker.trackEvent(Events.SEARCH_OFFERS_WAIT_TIME, 'measured', {
        waitTime,
      });
    }
  }, [loading, tracker]);

  const defaultProductFilter = useMemo(() => {
    const filter = getDefaultProductFilter({
      hasInternet: informantForm?.values?.serviceLines?.includes('internet'),
      hasTv: informantForm?.values?.serviceLines?.includes('tv'),
      hasPhone: informantForm?.values?.serviceLines?.includes('homePhone'),
    });

    return filter ? [filter] : [];
  }, [informantForm]);
  const {
    setAvailableProviders,
    setProductTypes,
    offers: filteredSortedOffers,
    allOffers,
  } = useFilterAndSortOffers({
    defaultSortMethod: SortMethod.PriceLowToHigh,
    defaultSelections: {
      products: defaultProductFilter,
      providers: [],
      additionalLanguages: [],
    },
    offersWithProviders,
  });
  setAvailableProviders(availableProviders as ServiceabilityProvider[]);
  setProductTypes(productTypes as ProductTypes[]);
  const isSingleProviderSite =
    micrositeConfig.shopType === ShopType.SINGLE_PROVIDER;

  // This should be readable but the logic for the loading screen:
  // (1) if it's multi-provider site, we only look at the primaryData
  // since we're requesting all providers at once;
  // (2) if it's a single provider site, we have to load the primary
  // and the secondary data so we can handle the cross-sell modal.
  // Additionally, if this microsite has updater cross-sell providers,
  // we must also wait for the tertiary data.  The LoadingDisplay
  // component will change state if we're waiting only for the tertiaryData,
  // so this component only needs to know whether the LoadingDisplay
  // should show or not.
  const showLoadingScreen = useMemo(() => {
    let showLoading = false;
    if (siteMode === SiteMode.DEFAULT_MODE) {
      if (isSingleProviderSite) {
        if (primaryData?.loading) {
          showLoading = true;
        } else {
          // if we have primary offers to show, we don't need to wait
          // for the secondaryData to load
          if (!hasOffers) {
            if (secondaryData?.loading) {
              showLoading = true;
            } else if (
              tertiaryData?.loading &&
              micrositeConfig.updaterCrossSellActive
            ) {
              showLoading = true;
            }
          }
        }
      } else {
        showLoading = primaryData.loading;
      }
    } else {
      // this should never fire?
    }
    return showLoading;
  }, [
    hasOffers,
    isSingleProviderSite,
    micrositeConfig.updaterCrossSellActive,
    primaryData?.loading,
    secondaryData?.loading,
    siteMode,
    tertiaryData?.loading,
  ]);

  useEffect(() => {
    if (showLoadingScreen) {
      tracker.trackEvent('offers_loading', 'viewed');
    } else {
      tracker.trackEvent(Events.PLANS, 'viewed');
    }
  }, [showLoadingScreen, tracker]);

  if (showLoadingScreen) {
    return <LoadingDisplay />;
  }

  const formattedAddress = address
    ? `${address?.street}, ${address?.unit ? `Apt ${address?.unit},` : ''} ${
        address?.city
      },  ${address?.state} ${address?.postalCode}`
    : '';

  const isActiveService =
    hasOffers &&
    offersWithProviders[0].offer?.tags?.find(
      (tag: any) => tag === 'active-service'
    );

  const updatedCheckoutType =
    isActiveService && micrositeConfig.activeServiceCheckoutType
      ? micrositeConfig.activeServiceCheckoutType
      : micrositeConfig.defaultCheckoutType;

  // Microsites where we can't sell directly should be redirected to
  // the provider site via the ProviderRedirectModal.  We also want to
  // suppress showing the offers here in case something gets borked
  // with the redirect
  const suppressOffers =
    updatedCheckoutType === CheckoutType.PROVIDER_REDIRECT &&
    siteMode === SiteMode.DEFAULT_MODE;

  const onBackClick = () => {
    setAcceptedUpdaterTerms(false);
    router.back();
  };

  return (
    <Box py="xxl" width="100%">
      <IconButton
        onClick={() => onBackClick()}
        size="s"
        icon={<ArrowLeft />}
        variant="secondary"
        type="button"
        aria-label="Back button"
        data-cy="backButton"
        data-testid="back-button"
      >
        Back
      </IconButton>
      <GridLayout gridGap="m" marginTop="m" includeMargin={false}>
        {/* Title */}
        <GridLayoutColumn
          span={['4', '4', '4', '8', '8']}
          spanStart="1"
          maxWidth="100%"
        >
          <Box>
            <Text
              as="h1"
              variant={['xxl', null, null, null, 'xxxl']}
              marginY="s"
            >
              Results
            </Text>
            {address && (
              <AddressContainer>
                <Box>
                  <MapPin size="20px" />
                </Box>
                <Text
                  variant={['m', null, 'l', null, null]}
                  marginX="s"
                  className="formattedAddress"
                  data-testid="formatted-address"
                >
                  {formattedAddress}
                </Text>
                <TextButton onClick={changeAddressHandler}>Change</TextButton>
              </AddressContainer>
            )}
          </Box>
        </GridLayoutColumn>
        {hasOffers && !suppressOffers && (
          <>
            <GridLayoutColumn
              span={['4', '4', '4', '4', '3']}
              overflow="hidden"
              maxWidth="100%"
            >
              <Flex width="100%" mb={['none', 'none', 'none', 'm']}>
                <Box flex={1}>
                  <SortOrderUI />
                </Box>
                {!(isLarge || isExtraLarge) && <ProductTypesUI />}
              </Flex>
              {(isLarge || isExtraLarge) && (
                <Box>
                  <ProductTypesUI />
                </Box>
              )}
            </GridLayoutColumn>

            <GridLayoutColumn
              span={['4', '4', '4', '8', '8']}
              spanStart={['1', '1', '1', '5', '4']}
              overflow="hidden"
              maxWidth="100%"
            >
              <NoPlansMatchResetFilters
                filteredSortedOffers={filteredSortedOffers}
                allOffers={allOffers}
              />
              <PlanProviderCards
                requestId={requestId}
                filteredSortedOffers={filteredSortedOffers}
              />
            </GridLayoutColumn>
          </>
        )}
        {!hasOffers && !suppressOffers && (
          <GridLayoutColumn span={['12', '12', '8', '8', '9']} spanStart="1">
            <Text as="h2" variant="m">
              We were unable to find offers at the given address.
            </Text>
          </GridLayoutColumn>
        )}
      </GridLayout>
      {isSingleProviderSite && (
        <>
          <CrossSellModal />
          <ProviderRedirectModal updatedCheckoutType={updatedCheckoutType} />
          <UpdaterCrossSellModal />
        </>
      )}
      <NotServiceableModal />
      <AsyncLoadingModal />
    </Box>
  );
};
