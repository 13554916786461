import {
  AddressModal,
  AddressSuggestionsModal,
  CommercialAddressModal,
} from 'components';

export function ShopWrapper({ children }) {
  return (
    <>
      {children}
      <AddressModal />
      <AddressSuggestionsModal />
      <CommercialAddressModal />
    </>
  );
}
