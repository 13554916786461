import styled from '@emotion/styled';
import { animated } from '@react-spring/web';

export const Container = animated(styled.div`
  display: grid;
  place-items: center;
  height: 500px;
  max-height: 50vh;
  border: ${({ theme }) => theme.colors.border};
`);
