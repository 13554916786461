import { Text, Box, Palette } from '@updater/ui-uds';
import { CallRailPhone, CallRailComponentType } from 'components';
import { Phone } from '@phosphor-icons/react';

export function LeadError() {
  return (
    <Box paddingY="xxl">
      <Box maxWidth="914px">
        <Text data-testid="could-not-process-header" as="h1" variant="xxl">
          We&apos;re sorry, your order could not be processed online.
        </Text>
        <Text variant="l" marginTop="l" marginBottom="m">
          But we&apos;re here to help! Call us now at the number below to speak
          with an agent directly:
        </Text>
        <Box minWidth="324px" maxWidth={['auto', null, null, null, '324px']}>
          <CallRailPhone
            componentType={CallRailComponentType.BUTTON}
            text="Call ###"
            variant="l"
            icon={<Phone size="24" weight="fill" />}
          />
        </Box>
        <Text variant="m" color={Palette.gray40} marginTop="m">
          Mon-Fri 7am to 1am EST & Sat-Sun 7am to 11pm EST
        </Text>
      </Box>
    </Box>
  );
}
