import { useContext, useEffect, useCallback } from 'react';
import { Box, Text, Card, Palette } from '@updater/ui-uds';
import styled from '@emotion/styled';
import { BackButton } from 'components/BackButton';
import router from 'next/router';
import { createLead, createLeadVariables } from 'types/generated/createLead';
import { useMutation } from '@apollo/client';
import { useTracking } from 'react-tracking';
import { createLeadMutation } from 'services/apollo/mutations';
import { useQual, useSessionStorage } from 'hooks';
import { MicrositeContext } from 'context';
import { Content } from './Content';
import { LeadModeForm } from './LeadModeForm';
import { Aside } from './Aside';
// TODO: constants folder should be referenceable as a root-level path...
import { providerAppSessionStorageKey } from '../../constants';
import { useOptionalFieldsById } from 'hooks/useOptionalFieldsById';
import { CreateLeadInput, OfferInput } from '@updater/consumer-graph';

export const Main = styled(Box)`
  max-width: 708px;
`;

export const Page = styled(Box)`
  max-width: 1265px;
  margin: 0 auto;
`;

export const DisclaimerCard = styled(Card)`
  border-color: ${Palette.blue30};
  background-color: ${Palette.blue10};
`;

export function LeadModePage() {
  const { micrositeCode } = useContext(MicrositeContext);
  const { gclid, partnerPropertyId } = useOptionalFieldsById();
  const { promoId, uuid, userEmail } = useQual();
  const [createLead] = useMutation<createLead, createLeadVariables>(
    createLeadMutation
  );
  const [sessionStorageValue, setSessionStorageValue] = useSessionStorage(
    providerAppSessionStorageKey,
    {}
  );
  const { trackEvent } = useTracking({
    object: 'lead_mode_info',
    details: {
      // TODO: what do we want this value to represent?
      provider: micrositeCode,
    },
  });

  useEffect(() => {
    trackEvent({
      verb: 'viewed',
    });
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      console.log({ submit: 'yes' });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { firstName, lastName, email, phone } = values;
      // The form forces a leading +1 on all phone numbers.  However,
      // serviceability and DC expect a 10-digit number.  If we pass
      // 11 digits, they will truncate and we'll lose the last digit.
      // Make sure the phone number is only 10 digits here:
      const tenDigitPhone =
        phone.length > 10 ? phone.substring(phone.length - 10) : phone;
      try {
        const response = await createLead({
          variables: {
            input: {
              requestId: sessionStorageValue?.requestId,
              promoId,
              optionalFields: {
                gclid,
                uuid,
              },
              partner: {
                partnerPropertyId,
                user: {
                  uuid,
                  email: userEmail,
                },
              },
              offer: {
                name: sessionStorageValue?.offer?.planName,
                code: sessionStorageValue?.offer?.code,
                providerCode: sessionStorageValue?.offer?.providerCode,
                pricing: sessionStorageValue?.offer?.pricing,
                products: sessionStorageValue?.offer?.products,
                providerOfferId: sessionStorageValue?.offer?.providerOfferId,
                metadata: sessionStorageValue?.offer?.metadata,
              } as OfferInput,
              customerInformation: {
                firstName,
                lastName,
                email,
                phone: tenDigitPhone,
              },
            } as CreateLeadInput,
          },
        });

        if (response?.data?.createLead?.leadId?.length) {
          setSessionStorageValue({
            ...sessionStorageValue,
            customerInformation: {
              firstName,
              lastName,
              email,
              phone,
              address: response?.data?.createLead?.address,
            },
            confirmationNumber: response?.data?.createLead?.leadId,
            confirmationPhoneNumber:
              response?.data?.createLead?.confirmationPhoneNumber,
            orderDate: new Date().toLocaleDateString(),
          });
          trackEvent({
            verb: 'submitted',
            details: {
              email,
              phone,
            },
          });
          router.push(`/confirmation`);
        } else {
          router.push(`/lead-error`);
        }
      } catch (error) {
        router.push(`/lead-error`);
      }
    },
    [router, createLead]
  );
  return (
    <Page>
      <Box marginTop="xl">
        <BackButton />
      </Box>
      <Text marginY="m" variant="xxxl">
        Order in a few easy steps!
      </Text>
      <Content>
        <Main>
          <LeadModeForm handleChange={() => {}} onSubmit={onSubmit} />
          <Box mt={['xxs', null, 'xs', null, null]}>
            <DisclaimerCard variant="squared" borderColor="light">
              <Text variant="s">
                By clicking “Submit”, I agree to allow order and installation
                information to be delivered via email and/or a recorded phone
                line.
              </Text>
            </DisclaimerCard>
          </Box>
          <Box my={['m', null, 'l', null, null]}>
            <DisclaimerCard variant="squared">
              <Text variant="s">
                YOU WILL BE ABLE TO SELECT EQUIPMENT, MODIFY ORDER, and schedule
                installation during the call to complete your order.
                Programming, pricing and offers are subject to change and may
                vary in certain markets. Some offers may not be available
                through all channels and in select areas.
              </Text>
            </DisclaimerCard>
          </Box>
        </Main>
        <Aside />
      </Content>
    </Page>
  );
}
