import { useEffect, useContext } from 'react';
import shallow from 'zustand/shallow';
import { MicrositeContext } from 'context';
import { Box } from '@updater/ui-uds';
import {
  PlanDataProvider,
  OffersWithProviders,
  ShowcaseOfferSection,
} from '@updater/ui-widgets';
import { useRouter } from 'next/router';
import { useTracking } from 'react-tracking';
import { useStore, useSessionStorage } from 'hooks';
import { CheckoutType } from 'types';
import type {
  ServiceabilityProvider,
  TvInternetOffer,
} from '@updater/consumer-graph';
import { providerAppSessionStorageKey } from '../../constants';
import { PlanCard } from 'components/PlanCard/PlanCard';
import { stripTypeNames } from 'utils/stripTypeNames';
import { useFeature, useFeatureValue } from '@growthbook/growthbook-react';
import { useOfferShowcase } from 'hooks/useOfferShowcase';
import { Target } from '@phosphor-icons/react';
import { getProviderConfig } from 'config';

export const PlanProviderCards = ({
  requestId,
  filteredSortedOffers,
}: {
  requestId: string;
  filteredSortedOffers?: OffersWithProviders;
}) => {
  const router = useRouter();
  const { getCheckoutType, phoneNumber } = useContext(MicrositeContext);
  const [sessionStorageValue, setSessionStorageValue] = useSessionStorage(
    providerAppSessionStorageKey,
    {}
  );
  const [setAsyncChosenOffer] = useStore(
    (store) => [store.setAsyncChosenOffer],
    shallow
  );
  const { trackEvent } = useTracking({
    object: 'offers',
  });

  const { on: xfinityBuyflowOn } = useFeature(
    'provider_app_tvi_xfinity_buyflow'
  );
  const updaterAttOfferEnabled = useFeatureValue(
    'provider_app_att_offer',
    false
  );

  const handleLeadModeCheckout = () => {
    router.push(`/full-order`);
  };
  const handleAsyncCheckout = (offer) => {
    setAsyncChosenOffer(stripTypeNames(offer));
  };

  const showcasedOfferWithProvider = useOfferShowcase(
    filteredSortedOffers,
    'provider_app_tvi_showcase'
  );

  const onClickSelectButton = ({
    offer,
    provider,
  }: {
    offer: TvInternetOffer;
    provider: ServiceabilityProvider;
  }) => {
    const hasInternet = offer.products.internet;
    const hasTV = offer.products.tv;
    const hasPhone = offer.products.phone;
    const hasOnlyInternet = hasInternet && !hasPhone && !hasTV;
    trackEvent({
      verb: 'submitted',
      details: {
        hasInternet,
        hasTV,
        hasPhone,
        hasOnlyInternet,
        offer,
      },
    });
    const checkoutType = getCheckoutType(offer as TvInternetOffer);
    if (checkoutType === CheckoutType.CALL_MODE) {
      console.log('[CHECKOUT]: call mode');
      return;
    }

    // We always add the chosen offer to the cart in sessionStorage.
    // If the chosen offer is async-eligible, we still need to query
    // the DC API and match the providerOfferId with the serviceability
    // offer because the guest-buyflow app currently only works with DC.
    // If this call fails, or we can't find the matched offer in the DC
    // API response, then we can't send the user to the async buyflow
    // and we fall back to Lead Mode.

    const shortTermPriceCharge = offer?.pricing?.charges?.filter(
      ({ type }) => type === 'short_term'
    );
    const longTermPriceCharge = offer?.pricing?.charges?.filter(
      ({ type }) => type === 'long_term'
    );
    const shortTermPrice =
      shortTermPriceCharge.length > 0
        ? {
            amount: shortTermPriceCharge[0].amount,
            term: shortTermPriceCharge[0].term?.unit,
          }
        : undefined;

    const longTermPrice =
      longTermPriceCharge.length > 0
        ? {
            amount: longTermPriceCharge[0].amount,
            term: longTermPriceCharge[0].term?.unit,
          }
        : undefined;

    // All lead mode checkouts are dependent on this
    setSessionStorageValue(
      stripTypeNames({
        ...sessionStorageValue,
        requestId,
        offer: {
          providerName: provider?.name,
          pricingDescription: offer?.pricing?.description,
          code: offer?.code,
          providerCode: offer?.providerCode,
          planName: offer?.name,
          shortTermPrice,
          longTermPrice,
          providerOfferId: offer?.providerOfferId,
          pricing: offer?.pricing,
          products: offer?.products,
          metadata: offer?.metadata,
        },
      })
    );

    // Each microsite will have its own rules around when to send
    // users into the async checkout flow.  Run the offer through
    // the microsite's check function to see what checkout flow
    // this offer should trigger
    // Only xfinity async buyflow is supported at the moment, so
    // only that GB feature is checked with xfinityBuyflowOn

    if (checkoutType === CheckoutType.ASYNC && xfinityBuyflowOn) {
      console.log('[CHECKOUT]: async');
      handleAsyncCheckout(offer);
    } else {
      console.log('[CHECKOUT]: lead mode');
      handleLeadModeCheckout();
    }
  };

  useEffect(() => {
    if (filteredSortedOffers?.length > 0) {
      trackEvent({
        verb: 'viewed',
        details: {
          offers: filteredSortedOffers,
        },
      });
    }
  }, [filteredSortedOffers]);

  return (
    <Box data-testid="plan-provider-card-box">
      {showcasedOfferWithProvider && (
        <ShowcaseOfferSection
          showcaseIcon={<Target />}
          showcaseTitle="Best Value"
          showcaseTagline="Here's a top option for you that provides high speed at a great price"
          showcaseOtherline="Other top options available at this address"
        >
          <PlanDataProvider
            provider={showcasedOfferWithProvider.provider}
            offer={showcasedOfferWithProvider.offer}
            planViewData={{
              broadbandDisplayType: getProviderConfig({
                providerCode: showcasedOfferWithProvider.provider.code,
              }).broadbandDisplayType,
              updaterAttOfferEnabled,
            }}
          >
            <PlanCard
              data-testid="plan-card"
              mt="s"
              backgroundColor="white"
              onClickSelectButton={() =>
                onClickSelectButton({
                  offer: showcasedOfferWithProvider.offer,
                  provider: showcasedOfferWithProvider.provider,
                })
              }
              cta={
                !!(
                  getCheckoutType(
                    showcasedOfferWithProvider.offer as TvInternetOffer
                  ) === CheckoutType.CALL_MODE
                )
                  ? { type: 'call', hotline: phoneNumber }
                  : { type: 'checkout' }
              }
            />
          </PlanDataProvider>
        </ShowcaseOfferSection>
      )}
      {filteredSortedOffers?.map(({ provider, offer }) => (
        <PlanDataProvider
          key={offer.code}
          provider={provider}
          offer={offer}
          planViewData={{
            broadbandDisplayType: getProviderConfig({
              providerCode: provider.code,
            }).broadbandDisplayType,
            updaterAttOfferEnabled,
          }}
        >
          <PlanCard
            data-testid="plan-card"
            backgroundColor="white"
            onClickSelectButton={() => onClickSelectButton({ offer, provider })}
            cta={
              !!(
                getCheckoutType(offer as TvInternetOffer) ===
                CheckoutType.CALL_MODE
              )
                ? { type: 'call', hotline: phoneNumber }
                : { type: 'checkout' }
            }
          />
        </PlanDataProvider>
      ))}
    </Box>
  );
};
