import styled from '@emotion/styled';
import { useEffect, useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  TextLink,
} from '@updater/ui-uds';
import { ShopType } from 'types';
import { Phone } from '@phosphor-icons/react';
import { useAddressSuggestions, useStore } from 'hooks';
import { useTracking } from 'react-tracking';
import { MicrositeContext, OffersContext } from 'context';
import { CallRailPhone, CallRailComponentType } from 'components';
import { useShallow } from 'zustand/react/shallow';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const ServiceMessage = styled(Text)`
  max-width: 500px;
  text-align: center;
`;

export function NotServiceableModal() {
  const offersContext = useContext(OffersContext);
  const micrositeConfig = useContext(MicrositeContext);
  const { shopType, homepage, noOffersRetry } = micrositeConfig;
  const { primaryData, secondaryData } = offersContext;
  const { addressUnavailable } = useAddressSuggestions();
  const [unavailableAddressCount] = useStore(
    useShallow((store) => [store.unavailableAddressCount])
  );

  const modalIsOpen =
    primaryData.loading === false &&
    (!secondaryData || (secondaryData && secondaryData.loading === false)) &&
    typeof primaryData.availableOffers !== undefined &&
    primaryData.availableOffers?.length === 0 &&
    (!secondaryData ||
      (typeof secondaryData.availableOffers !== undefined &&
        secondaryData.availableOffers?.length === 0)) &&
    addressUnavailable &&
    unavailableAddressCount >= noOffersRetry;

  function closeModal() {
    // setNotServieableModal(false);
  }

  const { trackEvent } = useTracking({
    object: 'not_serviceable_modal',
  });

  useEffect(() => {
    if (modalIsOpen) {
      trackEvent({
        verb: 'viewed',
      });
    }
  }, [modalIsOpen]);

  let titleMessage;
  if (
    shopType === ShopType.SINGLE_PROVIDER &&
    'mainProvider' in micrositeConfig
  ) {
    const { mainProvider } = micrositeConfig;
    titleMessage = `${mainProvider.displayName} is not available at your address.`;
  } else {
    titleMessage = 'No offers are available at your address.';
  }
  return (
    <Modal isOpen={modalIsOpen} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="736" minWidth="1" minHeight="1">
        <ModalBody padding="l" marginBottom="xxxxl">
          <Container>
            <Text
              data-testid="title-text"
              as="h1"
              variant={['mBold', null, 'xlBold', null, null]}
              marginBottom="l"
              textAlign="center"
            >
              {titleMessage}
            </Text>
            <ServiceMessage
              data-testid="service-message"
              variant="m"
              marginBottom="l"
            >
              Unfortunately we were unable to find offers at your address.
              Please call in to speak with one of our agents now, as agents have
              access to other offers not available online:
            </ServiceMessage>
            <CallRailPhone
              componentType={CallRailComponentType.BUTTON}
              text="Call ###"
              buttonSize="l"
              icon={<Phone size="24" weight="fill" />}
            />
            <TextLink href={homepage} marginTop="l" target="_top">
              Return to Homepage
            </TextLink>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
