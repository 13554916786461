import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
  //Earthlink,
  Comcast,
  Cox,
  Spectrum,
  Mediacom,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
} from 'config/providers';
import { FrontierTheme } from 'config/themes';

export const FrontierAuthorizedOffers: SingleProviderConfig = {
  name: 'FrontierAuthorizedOffers',
  micrositeCode: 'Frontier',
  mainProvider: Frontier,
  shopType: ShopType.SINGLE_PROVIDER,
  // shopType: ShopType.SYNTHETIC,
  domain: 'frontierauthorizedoffers.com',
  homepage: 'https://frontierauthorizedoffers.com',
  title: 'Frontier Authorized Dealer',
  phoneNumber: '877-477-3821',
  leadModePhoneNumber: '977-476-9020',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: FrontierTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'Frontier',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    ATT,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Verizon,
    Windstream,
    CincinnatiBell,
    //Earthlink,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    Comcast,
    Cox,
    Spectrum,
    Mediacom,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
};
