import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
  //Earthlink,
} from 'config/providers';
import { ATTTheme } from 'config/themes';

export const ATTSpecial: SingleProviderConfig = {
  name: 'ATTSpecial',
  micrositeCode: 'ATT',
  mainProvider: ATT,
  shopType: ShopType.SINGLE_PROVIDER,
  // shopType: ShopType.SYNTHETIC,
  domain: 'attspecial.com',
  homepage: 'https://attspecial.com',
  title: 'AT&T Authorized Dealer',
  phoneNumber: '866-878-7382',
  leadModePhoneNumber: '866-428-5671',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: ATTTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'ATT',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  updaterCrossSellActive: false,
  crossSellProviders: [
    Verizon,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    //Earthlink,
  ],
  guestBuyflowUrls: {
    development:
      'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    staging: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    qa: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    production: 'https://cart.attspecial.com/v2/checkout',
  },
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.attspecial.com',
  },
};
