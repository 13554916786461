import { MultiProviderConfig, ShopType, CheckoutType } from 'types';
import { allLeadMode } from 'config';
import { ProviderConfigList } from 'config/providers';
import { UpdaterTheme } from 'config/themes';

export const UpdaterInternet: MultiProviderConfig = {
  name: 'UpdaterInternet',
  micrositeCode: 'Updater',
  shopType: ShopType.MULTI_PROVIDER,
  domain: 'updater.com',
  homepage: 'https://updater.com/internet',
  title: 'Updater Internet Shopping',
  // Phone number grabbed from Swap Target here:
  // https://app.callrail.com/settings/a/501092119/routing/calls-and-texts/numbers/378516864/edit?company_id=947929203
  phoneNumber: '888-626-0260',
  leadModePhoneNumber: '866-436-7680',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: UpdaterTheme,
  needsDcSession: false,
  isIFramed: false,
  updaterCrossSellActive: false,
  source: 'Updater',
  noOffersRetry: 2,
  // For now, we do not include Earthlink on updater.com/internet
  providers: ProviderConfigList.filter(
    (provider) => provider.providerCode !== 'earthlink'
  ),
  getCheckoutType: allLeadMode,
};
