import styled from '@emotion/styled';
import { Palette } from '@updater/ui-uds';

const overallHeight = 64;
const radioSize = 40;

export const Wrapper = styled.div`
  width: 100%;

  label {
    position: relative;
    background-color: ${Palette.gray96};
    border: 1px solid ${Palette.gray96};
    cursor: pointer;
    border-radius: 32px;
    padding: ${`${(overallHeight - radioSize) / 2}px ${
      (overallHeight - radioSize) / 2 + 4
    }px`};
    width: 100%;
    max-width: 536px;
    align-items: center;

    &:hover {
      background-color: ${Palette.gray90};
    }

    &::before {
      content: ' ';
      background: white;
      position: absolute;
      width: ${`${radioSize}px`};
      height: ${`${radioSize}px`};
      border-radius: 50%;
    }
  }

  input {
    border-radius: 50%;
    width: ${`${radioSize}px`};
    height: ${`${radioSize}px`};
    position: relative;
    z-index: 1;
  }
`;

export function CheckListWrapper({ children }) {
  return <Wrapper data-testid="checklist_wrapper">{children}</Wrapper>;
}
