import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  Box,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  RadioList,
  Text,
  TextLink,
} from '@updater/ui-uds';
import { useAddressSuggestions, useStore } from 'hooks';
import { Events, useTracking } from 'services';
import { Address } from 'types';
import { ContinueButton } from './ContinueButton';

export function AddressSuggestionsModal() {
  const tracking = useTracking();
  const { addressSuggestions } = useAddressSuggestions();
  const [addressIndex, setAddressIndex] = useState<string>();
  const [setAddress, wantsNewAddress, setWantsNewAddress] = useStore(
    useShallow((store) => [
      store.setAddress,
      store.wantsNewAddress,
      store.setWantsNewAddress,
    ])
  );
  const isOpen = addressSuggestions?.length > 0 && !wantsNewAddress;
  const handleChangeAddress = () => {
    tracking.trackEvent(Events.ADDRESS_SUGGESTIONS_VERIFY_ADDRESS, 'clicked');
    setWantsNewAddress(true);
  };

  useEffect(() => {
    if (isOpen) {
      tracking.trackEvent(Events.ADDRESS_SUGGESTIONS, 'viewed');
    }
  }, [isOpen]);

  function handleSubmit() {
    const address = addressSuggestions[addressIndex] as Address;
    tracking.trackEvent(Events.ADDRESS_SUGGESTIONS, 'submitted', {
      newAddress: address,
    });
    setAddress(address, true);
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={() => {}}>
      <ModalOverlay />
      <ModalContent maxWidth="600px" minWidth="1" minHeight="1">
        <ModalHeader>
          <ModalTitle variant="xlBold" style={{ fontSize: '20px' }}>
            Select address
          </ModalTitle>
        </ModalHeader>

        <ModalBody>
          <Grid gap="17px">
            <Text variant="m">
              We found the following addresses. Which one is yours?
            </Text>
            <RadioList
              onChange={(value) => setAddressIndex(value)}
              options={addressSuggestions?.map((suggestion, index) => ({
                label: `${suggestion.street}, ${
                  suggestion.unit ? `${suggestion.unit}, ` : ''
                }${suggestion.city}, ${suggestion.postalCode}`,
                value: index.toString(),
              }))}
              value={addressIndex}
              variant="pill"
            />
            <Text variant="m">
              Can’t find your address in the list?{' '}
              <TextLink underlineOnHover onClick={handleChangeAddress}>
                Try entering it again
              </TextLink>
            </Text>
          </Grid>
        </ModalBody>

        <ModalFooter flexDirection="column">
          <Box display="flex" style={{ flexDirection: 'column' }}>
            <ContinueButton
              variant="primary"
              onClick={handleSubmit}
              disabled={!addressIndex}
            >
              Continue
            </ContinueButton>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
