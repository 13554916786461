import styled from '@emotion/styled';

export const Content = styled.div`
  display: grid;
  grid-gap: 30px;
  width: 100%;
  max-width: 1265px;
  margin: 0 auto 100px auto;
  justify-content: space-between;
  grid-template-columns: 1fr;
  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: 1fr 476px;
  }
`;
