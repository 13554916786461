import { MultiProviderConfig, ShopType, CheckoutType } from 'types';
import { TvInternetOffer } from '@updater/consumer-graph';
import { ProviderConfigList } from 'config/providers';
import { UpdaterTheme } from 'config/themes';
import { allLeadMode } from 'config';

export const AppFolio: MultiProviderConfig = {
  name: 'AppFolio',
  micrositeCode: 'AppFolio',
  originatorId: '681',
  shopType: ShopType.MULTI_PROVIDER,
  domain: 'updater.com',
  homepage: 'https://partner-cart.updater.com/provider/',
  suppressHeaderLogoLinkBack: true,
  title: 'Updater Internet Shopping for AppFolio Residents',
  phoneNumber: '888-970-0053',
  leadModePhoneNumber: '833-288-4510',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: UpdaterTheme,
  needsDcSession: false,
  isIFramed: false,
  updaterCrossSellActive: true,
  source: 'AppFolio',
  noOffersRetry: 2,
  // For now, we do not include Earthlink or TDS on AppFolio
  // https://updater.atlassian.net/browse/TVI-245
  providers: ProviderConfigList.filter(
    (provider) =>
      provider.providerCode !== 'earthlink' && provider.providerCode !== 'tds'
  ),
  // TODO: The below urls assume only Xfinity Buyflow exists
  guestBuyflowUrls: {
    development: 'http://bridgevine.io:3000/v2/checkout',
    staging: 'https://staging-cart.updater.com/v2/checkout',
    qa: 'https://staging-cart.updater.com/v2/checkout',
    production: 'https://partner-cart.updater.com/v2/checkout',
  },
  // TODO: this implementation is copy pasted all over, refactor out?
  getCheckoutType: allLeadMode,
  // (offer: TvInternetOffer) => {
  //   // This is a stopgap for Comcast issues where the modem
  //   // price is incorrect for certain offers.  We have no mechanism
  //   // for offer-level logic in the guest-buyflow app.  Our only
  //   // solution is to route users to Lead Mode if certain offers can't
  //   // be shown in the async flow.
  //   const isDummyOffer = Boolean(offer?.metadata?.dummy);
  //   if (isDummyOffer) {
  //     switch (offer?.metadata?.dummyOfferType) {
  //       case 'basic':
  //         return CheckoutType.CALL_MODE;
  //       case 'enhanced':
  //         return CheckoutType.LEAD_MODE;
  //       default:
  //         return CheckoutType.CALL_MODE;
  //     }
  //   }
  //   const excludedOffers = [
  //     '9626220318',
  //     '9626150272',
  //     '9626101594',
  //     '9626101624',
  //   ];
  //   const exclude =
  //     excludedOffers.includes(offer.providerOfferId) ||
  //     offer?.providerCode !== 'xfinity';
  //   console.log(`[XfinitySpecial] providerOfferId ${offer.providerOfferId}`);
  //   if (exclude) {
  //     console.log(`[XfinitySpecial] async exclusion`);
  //   }

  //   const { internet, phone, tv } = offer.products;
  //   const hasOnlyInternet = internet && !phone && !tv;
  //   if (hasOnlyInternet && !exclude) {
  //     return CheckoutType.ASYNC;
  //   }
  //   return CheckoutType.LEAD_MODE;
  // },
  optionalFieldsMap: ['partnerPropertyId', null, null, null, null],
  asyncConfig: {
    theme: 'updater',
    supportPhoneNumber: '888-970-0053',
    headerLogoLinkUrl: '',
  },
  gtmCode: 'GTM-W5S4DCN4',
  suppressNormalizedAddressCheck: true,
  trackUpdaterTermsAgreement: true,
  // https://updater.atlassian.net/jira/software/c/projects/TVI/boards/439?assignee=60bbd7523aa6050068a5fca0&selectedIssue=TVI-237
  enabledTrackingDetails: [
    'user_uuid',
    'property_uuid',
    'originator_id',
    'promo_id',
    'user_email',
  ],
  hideFirstPageBackButton: true,
  enableAppfolioLanding: true,
  skipToPlansOnRevisit: true,
};
