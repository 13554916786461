import { MicrositeContext } from 'context/MicrositeContext';
import { useQual } from 'hooks/useQual';
import { useContext, useEffect } from 'react';
import { useEnvironment } from 'services/environment';

/**
 * Identifies user based on UUID.
 */
export function Fullstory({ children }) {
  const { uuid, userFirstName, userLastName, userEmail } = useQual();
  const { name: environment } = useEnvironment();
  const micrositeConfig = useContext(MicrositeContext);

  useEffect(() => {
    if (uuid) {
      window?.FS?.(uuid);
      window?.FS?.identify(uuid, {
        uid: uuid,
        email: userEmail,
        application: 'provider-app',
        environment,
        displayName: `${userFirstName || ''} ${userLastName || ''}`,
        source: micrositeConfig?.source,
      });
    }
  }, [
    uuid,
    userEmail,
    userFirstName,
    userLastName,
    environment,
    micrositeConfig?.source,
  ]);

  return <>{children}</>;
}
