import { SingleProviderConfig, ShopType, CheckoutType } from 'types';
import { getDefaultCrossSellAction } from 'config';
import {
  Comcast,
  Cox,
  Spectrum,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  Mediacom,
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
} from 'config/providers';
import { XfinityTheme } from 'config/themes';
import { TvInternetOffer } from '@updater/consumer-graph';

export const XfinitySpecial: SingleProviderConfig = {
  name: 'XfinitySpecial',
  micrositeCode: 'Comcast',
  mainProvider: Comcast,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'xfinityspecial.com',
  homepage: 'https://xfinityspecial.com',
  title: 'Xfinity Authorized Dealer',
  phoneNumber: '866-417-5505',
  leadModePhoneNumber: '866-495-3522',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: XfinityTheme,
  needsDcSession: true,
  isIFramed: false,
  source: 'Xfinity',
  noOffersRetry: 2,
  commercialHomepage: 'https://comcastbusinessspecial.com',
  getCrossSellAction: getDefaultCrossSellAction,
  crossSellProviders: [
    Cox,
    Spectrum,
    Mediacom,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    Verizon,
  ],
  guestBuyflowUrls: {
    development: 'http://bridgevine.io:3000/v2/checkout',
    staging: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    qa: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    production: 'https://cart.xfinityspecial.com/v2/checkout',
  },
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.xfinityspecial.com',
  },
  getCheckoutType: (offer: TvInternetOffer) => {
    // This is a stopgap for Comcast issues where the modem
    // price is incorrect for certain offers.  We have no mechanism
    // for offer-level logic in the guest-buyflow app.  Our only
    // solution is to route users to Lead Mode if certain offers can't
    // be shown in the async flow.
    const excludedOffers = [
      '9626220318',
      '9626150272',
      '9626101594',
      '9626101624',
    ];
    const exclude =
      excludedOffers.includes(offer.providerOfferId) ||
      offer?.providerCode !== 'xfinity';
    console.log(`[XfinitySpecial] providerOfferId ${offer.providerOfferId}`);
    if (exclude) {
      console.log(`[XfinitySpecial] async exclusion`);
    }

    const { internet, phone, tv } = offer.products;

    const hasOnlyInternet = internet && !phone && !tv;
    if (hasOnlyInternet && !exclude) {
      return CheckoutType.ASYNC;
    }
    return CheckoutType.LEAD_MODE;
  },
  asyncConfig: {
    headerLogoLinkUrl: 'https://xfinityspecial.com',
  },
};
