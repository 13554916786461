import styled from '@emotion/styled';

export const Container = styled.header`
  align-items: center;
  background-color: ${({ theme }) => {
    return theme.customTheme.colors.headerBackground;
  }} !important;
  color: ${({ theme }) => theme.customTheme.colors.headerText};
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.customTheme.colors.headerBorder};
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  height: 57px;
  min-height: 57px;
  max-height: 57px;
  ${({ theme }) =>
    theme.customTheme.colors.headerGradient
      ? `&::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      height: 6px;
      background: linear-gradient(${theme.customTheme.colors.headerGradient});
      content: '';
      }`
      : ''}

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 72px;
    min-height: 72px;
    max-height: 72px;
  }
`;
