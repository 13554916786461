import NextHead from 'next/head';
import getConfig from 'next/config';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { MicrositeContext } from 'context';

type HeadProps = {
  overrideTitle?: string;
};

export function Head({ overrideTitle }: HeadProps) {
  const { title } = useContext(MicrositeContext);
  const theme = useTheme();
  const { publicRuntimeConfig } = getConfig();
  const { basePath } = publicRuntimeConfig;
  const finalTitle = overrideTitle || title || '';

  const folder = theme.customTheme.name;
  const url = (size) => {
    return `${basePath}/themes/${folder}/favicon/site-icon-${size}x${size}.png`;
  };
  const sizes = [32, 192, 180];
  const rels = ['icon', 'icon', 'apple-touch-icon'];

  return (
    <NextHead>
      <title>{finalTitle}</title>
      <meta name="robots" content="noindex" />
      {sizes.map((size, index) => {
        return (
          <link
            key={`icon-${index}`}
            rel={rels[index]}
            sizes={`${size}x${size}`}
            href={url(size)}
          />
        );
      })}
    </NextHead>
  );
}
