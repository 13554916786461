import { Box } from '@updater/ui-uds';
import { OrderSummary } from '../OrderSummary';
import { SupportInformation } from '../SupportInformation';
import { useContext } from 'react';
import { MicrositeContext } from 'context';

export const Aside = () => {
  const { hideSupportInfoBox } = useContext(MicrositeContext);
  return (
    <Box>
      <OrderSummary />
      {!Boolean(hideSupportInfoBox) && <SupportInformation />}
    </Box>
  );
};
