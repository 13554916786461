import styled from '@emotion/styled';

export const Highlight = styled.th`
  text-align: left;
  vertical-align: top;
  &:first-of-type {
    padding-left: ${({ theme }) => `${theme.space.l}px`};
  }
  &:last-of-type {
    padding-right: ${({ theme }) => `${theme.space.l}px`};
  }
`;
