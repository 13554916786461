import { MouseEvent, useEffect } from 'react';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalTitle,
  Text,
  Palette,
  useTheme,
} from '@updater/ui-uds';
// TODO: why can't we use short name for the constants folder?
import { useStore } from 'hooks';
import { Events, useTracking } from 'services';
import { internetSpeedGuidelines } from '../../constants';
import { Table } from './Table';
import { TableRow } from './TableRow';
import { Highlight } from './Highlight';
import { Data } from './Data';
import { useShallow } from 'zustand/react/shallow';

export function InternetSpeedModal() {
  const theme = useTheme();
  const tracking = useTracking();
  const [showHelpChoosing, setShowHelpChoosing] = useStore(
    useShallow((store) => [store.showHelpChoosing, store.setShowHelpChoosing])
  );

  useEffect(() => {
    if (showHelpChoosing) {
      tracking.trackEvent(Events.INTERNET_SPEED, 'viewed');
    }
  }, [showHelpChoosing]);

  function closeModal() {
    setShowHelpChoosing(false);
  }
  function closeEvent(event: MouseEvent) {
    event.stopPropagation();
    closeModal();
  }

  return (
    <Modal isOpen={showHelpChoosing} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="700px" minWidth="1" minHeight="1">
        <ModalHeader p="l">
          <ModalTitle variant={['mBold', null, 'xlBold', null, null]}>
            What internet speed do you need?
          </ModalTitle>
          <ModalCloseButton onClick={closeEvent} />
        </ModalHeader>
        <div style={{ marginTop: `-${theme.space.s}px` }}>
          <Table>
            <thead>
              <tr>
                <Highlight>
                  <Text variant={['xsBold', null, 'mBold', null, null]}>
                    How do you use the internet?
                  </Text>
                </Highlight>
                <Highlight>
                  <Text variant={['xsBold', null, 'mBold', null, null]}>
                    Minimum download speed (Mbps)*
                  </Text>
                </Highlight>
                <Highlight>
                  <Text variant={['xsBold', null, 'mBold', null, null]}>
                    Recommended speed
                  </Text>
                </Highlight>
              </tr>
            </thead>
            <tbody>
              {internetSpeedGuidelines.map((item) => {
                return (
                  <TableRow>
                    <Data>
                      <Text variant={['xs', null, 'm', null, null]}>
                        {item.activity}
                      </Text>
                    </Data>
                    <Data>
                      <Text variant={['xs', null, 'm', null, null]}>
                        {item.minDownloadSpeed}
                      </Text>
                    </Data>
                    <Data>
                      <Text variant={['xsBold', null, 'mBold', null, null]}>
                        {item.recommendedSpeed}
                      </Text>
                    </Data>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
          <Text
            data-testid="fcc-text"
            variant="xs"
            color={Palette.gray30}
            padding="l"
            paddingTop="s"
          >
            *These numbers are rough guidelines and are not based on surveys or
            expeeriments conducted by the FCC. Speeds are based on running one
            activity at a time. You should use your best judgment when choosing
            your broadband service. Federal Communications Commission, (2018,
            Feb 6). Broadband Speed Guide, Retrieved from fcc.gov
          </Text>
        </div>
      </ModalContent>
    </Modal>
  );
}
