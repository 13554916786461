import Link from 'next/link';
import getConfig from 'next/config';
import { useContext } from 'react';
import { MicrositeContext } from 'context';
import { useTheme } from '@emotion/react';

const Logo = ({ path, width, height }) => {
  const style = {
    backgroundImage: `url(${path})`,
    display: 'block',
    width: `${width}`,
    height: `${height}`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionY: '50%',
  };
  return <div style={style} />;
};

export const BrandLogo = () => {
  const { publicRuntimeConfig } = getConfig();
  const { basePath } = publicRuntimeConfig;
  // Access the theme from the hook; this will handle both
  // the default mode and the reskin mode logos
  const theme = useTheme();
  const { homepage, suppressHeaderLogoLinkBack } = useContext(MicrositeContext);
  const folder = theme.customTheme.name;
  const { logoSize } = theme.customTheme;
  const path = `${basePath}/themes/${folder}/logo.svg`;
  if (Boolean(suppressHeaderLogoLinkBack))
    return <Logo path={path} width={logoSize.width} height={logoSize.height} />;
  return (
    <Link href={homepage} data-testid="brand-logo">
      <Logo path={path} width={logoSize.width} height={logoSize.height} />
    </Link>
  );
};

export default BrandLogo;
