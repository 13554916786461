import { SuperSchema } from '@updater/ui-informant';

export const tvSchema: SuperSchema = {
  if: {
    properties: {
      serviceLines: {
        contains: { const: 'tv' },
      },
    },
  },
  then: {
    type: 'object',
    required: ['tv'],
    properties: {
      tv: {
        type: 'array',
        minItems: 1,
        required: ['oneOf'],
        items: {
          type: 'string',
          oneOf: [
            {
              const: 'localChannelsAndNews',
              title: 'Local channels & news',
            },
            {
              const: 'entertainmentLifestyleAndKidsChannels',
              title: 'Entertainment, lifestye & kids channels',
            },
            {
              const: 'sportsAndPremiumChannels',
              title: 'Sports & premium channels',
            },
            {
              const: 'latinoChannels',
              title: 'Latino channels',
            },
          ],
        },
        ui: {
          component: 'checklist',
          wrapperComponent: 'CheckListWrapper',
          componentProps: {
            options: [
              {
                label: 'Local channels & news',
                value: 'localChannelsAndNews',
                id: 'localChannelsAndNews',
              },
              {
                label: 'Entertainment, lifestye & kids channels',
                value: 'entertainmentLifestyleAndKidsChannels',
                id: 'entertainmentLifestyleAndKidsChannels',
              },
              {
                label: 'Sports & premium channels',
                value: 'sportsAndPremiumChannels',
                id: 'sportsAndPremiumChannels',
              },
              {
                label: 'Latino channels',
                value: 'latinoChannels',
                id: 'latinoChannels',
              },
            ],
          },
        },
      },
    },
  },
};
