import { ShopType, CheckoutType, MultiProviderConfig } from 'types';
import { allLeadMode } from 'config';
import { ProviderConfigList } from 'config/providers';
import { DefaultTheme } from 'config/themes';

export const FluentIFramed: MultiProviderConfig = {
  name: 'FluentIFramed',
  micrositeCode: 'FluentIFrame',
  shopType: ShopType.MULTI_PROVIDER,
  domain: 'https://partner-cart.updater.com',
  homepage: '',
  hideFirstPageBackButton: true,
  hideSupportInfoBox: true,
  title: 'Fluent Shop For Internet',
  phoneNumber: '833-287-1440',
  leadModePhoneNumber: '833-287-1440',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: DefaultTheme,
  hasUpdaterCobranding: false,
  needsDcSession: false,
  isIFramed: true,
  updaterCrossSellActive: false,
  source: 'FluentIFrame',
  noOffersRetry: 2,
  getCheckoutType: allLeadMode,
  providers: ProviderConfigList,
};
