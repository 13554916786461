import styled from '@emotion/styled';
import { useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  ButtonLink,
} from '@updater/ui-uds';
import { useTracking } from 'react-tracking';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const ServiceMessage = styled(Text)`
  max-width: 500px;
  text-align: center;
`;
const Reason = styled(Text)`
  max-width: 500px;
  font-size: 10px;
  text-align: center;
`;

export function QualErrorModal({ reason }) {
  const { trackEvent } = useTracking({
    object: 'qual_error_modal',
  });

  const modalIsOpen = true;

  useEffect(() => {
    if (modalIsOpen) {
      trackEvent({
        verb: 'viewed',
      });
    }
  }, []);

  function closeModal() {}

  return (
    <Modal isOpen={modalIsOpen} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="736" minWidth="1" minHeight="1">
        <ModalBody padding="l" marginBottom="xxxxl">
          <Container>
            <Text
              as="h1"
              variant={['mBold', null, 'xlBold', null, null]}
              marginBottom="l"
              textAlign="center"
            >
              There was an error qualifying for offers.
            </Text>
            <ServiceMessage variant="m" marginBottom="s">
              Please click the button below to re-qualify for offers from our
              homepage:
            </ServiceMessage>
            <ButtonLink
              href="/"
              marginTop="s"
              marginBottom="l"
              dataCy="back-home-button"
              target="_top"
              size="l"
            >
              Return to Homepage
            </ButtonLink>
            <Reason>{reason}</Reason>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
