import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  Comcast,
  Cox,
  Spectrum,
  Mediacom,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
} from 'config/providers';
import { OptimumTheme } from 'config/themes';

export const OptimumOffers: SingleProviderConfig = {
  name: 'OptimumOffers',
  micrositeCode: 'Optimum',
  mainProvider: Optimum,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'optimumoffers.com',
  homepage: 'https://optimumoffers.com',
  title: 'Optimum Authorized Dealer',
  phoneNumber: '833-678-5587',
  leadModePhoneNumber: '833-678-5588',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: OptimumTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'Optimum',
  noOffersRetry: 2,
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.optimumoffers.com',
  },
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    Comcast,
    Cox,
    Spectrum,
    Mediacom,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    Verizon,
  ],
};
