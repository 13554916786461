import {
  Alert,
  Box,
  Button,
  DropDownSelect,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
  RadioList,
  Text,
  useTheme,
} from '@updater/ui-uds';
import {
  useAddressSuggestions,
  useAddress,
  useStore,
  useNormalizedAddressQuery,
} from 'hooks';
import { CallRailPhone } from 'components';
import { Events, useTracking } from 'services';
import { addressLine2Options, stateList } from 'config';
import { useFormik } from 'formik';
import capitalize from 'lodash/capitalize';
import {
  useEffect,
  useContext,
  useRef,
  ChangeEvent,
  ComponentProps,
} from 'react';
import { OffersContext, MicrositeContext } from 'context';
import { Line2Type, ShopType, Address, SiteMode } from 'types';
import * as Yup from 'yup';
import { ContinueContainer } from './ContinueContainer';
import { Hint } from './Hint';
import { HintsContainer } from './HintsContainer';
import { SessionCookie } from 'services/auth';
import { useShallow } from 'zustand/react/shallow';
import { isValidZipRegex, transformZipInputValue } from 'utils/addressHelpers';

enum ModalOpenReason {
  SessionExpired = 0,
  NormalizedError,
  WantsNewAddress,
  UnitNeeded,
  NoOffersAvailable,
}

const reasonToString = [
  'SessionExpired',
  'NormalizedError',
  'WantsNewAddress',
  'UnitNeeded',
  'NoOffersAvailable',
];

const SubmitButton = (props: ComponentProps<typeof Button>) => (
  <Button
    variant="primary"
    type="submit"
    data-testid="submit-button"
    {...props}
    size="l"
  >
    Continue
  </Button>
);

export function AddressModal({ firstVisit = true }: { firstVisit?: boolean }) {
  const theme = useTheme();
  const { noOffersRetry, shopType, updaterCrossSellActive } =
    useContext(MicrositeContext);
  const sessionCookie = SessionCookie.read();
  const sessionExpired = !sessionCookie;
  const tracking = useTracking();
  const { primaryData, secondaryData, tertiaryData } =
    useContext(OffersContext);
  const addressSuggestionsData = useAddressSuggestions();
  const address = useAddress();
  const [
    setAddress,
    wantsNewAddress,
    setWantsNewAddress,
    unavailableAddressCount,
    incrementUnavailableAddressCount,
    deleteReskinProvider,
    setSiteMode,
  ] = useStore(
    useShallow((store) => [
      store.setAddress,
      store.wantsNewAddress,
      store.setWantsNewAddress,
      store.unavailableAddressCount,
      store.incrementUnavailableAddressCount,
      store.deleteReskinProvider,
      store.setSiteMode,
    ])
  );

  const isFirstVisit = useRef(firstVisit);

  const normalizedAddressResult = useNormalizedAddressQuery(address);
  // console.log('unavailableAddressCount', unavailableAddressCount);

  // The availableOffers query will return suggested addresses directly
  // from the provider's API.  We want to give precedence to these
  // suggestions over our own address normalization service.  If there
  // are suggestions from the provider and the address is marked in a
  // state that is not "unavailable", we want to hide this modal and
  // show the address suggestions modal instead.
  const hasSuggestions =
    addressSuggestionsData.loading === false &&
    addressSuggestionsData.addressUnavailable === false &&
    addressSuggestionsData.addressSuggestions.length > 0;

  // There are two signals that could come back from the normalization service:
  // (1) normalization error
  // (2) no error, but null normalized address
  // Both basically mean "We don't understand and couldn't validate this address"
  // TODO: when our own service returns INVALID, what do we do?
  const hasCommercialAddress =
    !normalizedAddressResult.loading &&
    normalizedAddressResult.data?.normalizedAddress?.normalizedAddress
      ?.classification === 'COMMERCIAL';

  const normalizedError =
    !isFirstVisit.current &&
    !normalizedAddressResult.loading &&
    !hasCommercialAddress &&
    (normalizedAddressResult.error !== undefined ||
      normalizedAddressResult.data?.normalizedAddress?.normalizedAddress ===
        null);

  // A number of different triggers can cause us to need to
  // reverify the address.  Set a single reason to determine
  // different rendering values and actions:
  let modalOpenReason: ModalOpenReason | undefined;
  if (sessionExpired) {
    modalOpenReason = ModalOpenReason.SessionExpired;
  } else if (wantsNewAddress) {
    modalOpenReason = ModalOpenReason.WantsNewAddress;
  } else if (normalizedError && hasSuggestions === false) {
    modalOpenReason = ModalOpenReason.NormalizedError;
  } else if (
    normalizedAddressResult.data?.normalizedAddress?.normalizedAddress
      ?.status === 'APT_NEEDED' &&
    hasSuggestions === false &&
    hasCommercialAddress === false
  ) {
    modalOpenReason = ModalOpenReason.UnitNeeded;
  } else if (
    !hasSuggestions &&
    unavailableAddressCount < noOffersRetry &&
    !hasCommercialAddress
  ) {
    if (shopType === ShopType.SINGLE_PROVIDER) {
      const noPrimaryOrSecondary =
        !primaryData.loading &&
        primaryData.availableOffers?.length === 0 &&
        !secondaryData.loading &&
        secondaryData.availableOffers?.length === 0;

      const noTertiary =
        !tertiaryData.loading && tertiaryData.availableOffers?.length === 0;

      if (updaterCrossSellActive === false) {
        if (noPrimaryOrSecondary) {
          modalOpenReason = ModalOpenReason.NoOffersAvailable;
        }
      } else {
        if (noPrimaryOrSecondary && noTertiary) {
          modalOpenReason = ModalOpenReason.NoOffersAvailable;
        }
      }
    } else if (shopType === ShopType.MULTI_PROVIDER) {
      if (!primaryData.loading && primaryData.availableOffers?.length === 0) {
        modalOpenReason = ModalOpenReason.NoOffersAvailable;
      }
    }
  }

  let modalIsOpen = false;
  if (modalOpenReason) {
    console.log('Address', reasonToString[modalOpenReason]);
    modalIsOpen = true;
  }

  // This fires the modal view event based on the reason the
  // modal was triggered open (or fires nothing if the modal
  // is closed).
  useEffect(() => {
    let eventName = null;
    switch (modalOpenReason) {
      case ModalOpenReason.SessionExpired:
        eventName = Events.SESSION_TIMEOUT;
        break;
      case ModalOpenReason.WantsNewAddress:
        eventName = Events.CHANGE_ADDRESS;
        break;
      case ModalOpenReason.UnitNeeded:
        eventName = Events.VERIFY_ADDRESS_UNIT_NEEDED;
        break;
      case ModalOpenReason.NormalizedError:
      case ModalOpenReason.NoOffersAvailable:
        eventName = Events.VERIFY_ADDRESS;
        break;
      default:
        break;
    }
    if (eventName !== null) {
      tracking.trackEvent(eventName, 'viewed');
    }
  }, [modalOpenReason, tracking]);

  const defaultLine2Type =
    address.line2Type || (address.unit && Line2Type.OTHER) || Line2Type.NONE;

  const addressForm = useFormik({
    initialValues: {
      ...address,
      line2Type: defaultLine2Type,
    },
    validationSchema: Yup.object({
      street: Yup.string().required(),
      city: Yup.string().required(),
      state: Yup.string().required(),
      postalCode: Yup.string()
        .trim()
        .required('zip code is a required field')
        // don't show an error when user may be entering the value, only disable the form submit
        .test('test-valid-zip-regex', '', isValidZipRegex),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const handlePostalCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    let cursorPosition = event.target.selectionEnd;
    const eventChar = event.target.value.slice(
      cursorPosition - 1,
      cursorPosition
    );
    const transformedValue = transformZipInputValue(event.target.value);
    // reset cursorPosition if transformed
    if (!eventChar.match(/\d/)) {
      cursorPosition--;
    }
    addressForm.setFieldValue('postalCode', transformedValue).then(() => {
      // after setting value, reset cursorPosition if its position is ahead
      if (
        transformedValue.indexOf('_') > 0 &&
        cursorPosition > transformedValue.indexOf('_')
      ) {
        cursorPosition = transformedValue.indexOf('_');
      }
      if (cursorPosition === -1) cursorPosition++;
      event.target.selectionEnd = cursorPosition;
    });
  };

  const handleContinueButtonClick = () =>
    addressForm.validateForm().then((errors) => {
      if (!Object.keys(errors).length) {
        isFirstVisit.current = false;
        handleSubmit();
      }
    });

  const hasFormErrors = Boolean(Object.keys(addressForm.errors).length);

  const handleSubmit = async () => {
    if (modalOpenReason === ModalOpenReason.NoOffersAvailable) {
      incrementUnavailableAddressCount();
    }

    // if they just clicked the Continue button without changing
    // any of the address data, force a refresh of the normalized
    // query anyway
    const isSameAddress = Object.keys(addressForm.values).every(
      (field) => addressForm.values[field] === address[field]
    );

    // TODO: i think we want to refetch every time?
    if (!isSameAddress) {
      normalizedAddressResult.refetch();
    }
    setSiteMode(SiteMode.DEFAULT_MODE);
    deleteReskinProvider();
    setAddress(addressForm.values as Address);
  };

  function closeModal() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (canCloseModal) {
      setWantsNewAddress(false);
    }
  }

  const canCloseModal = modalOpenReason === ModalOpenReason.WantsNewAddress;
  const showLine2 =
    addressForm.values.line2Type !== Line2Type.NONE ||
    (addressForm.values.unit &&
      addressForm.values.line2Type === Line2Type.NONE);

  let title = null;
  let subTitle = null;

  switch (modalOpenReason) {
    case ModalOpenReason.SessionExpired:
      title = 'Session timeout';
      subTitle =
        'Your session has timed out due to inactivity. Please verify your address and click Continue to qualify for offers.';
      break;
    case ModalOpenReason.WantsNewAddress:
      title = "What's your address?";
      break;
    case ModalOpenReason.UnitNeeded:
      title = 'Verify your address';
      subTitle =
        'We weren’t able to verify your address without an apartment/unit number.  Please update it below and click Continue to qualify for offers.';
      break;
    case ModalOpenReason.NormalizedError:
    case ModalOpenReason.NoOffersAvailable:
      title = 'Verify your address';
      subTitle =
        'Please verify your address below and click Continue to qualify for offers.';
      break;
    default:
      break;
  }

  return (
    <form onSubmit={addressForm.handleSubmit} data-testid="address-form">
      <Modal isOpen={modalIsOpen} setIsOpen={closeModal}>
        <ModalOverlay />
        <ModalContent maxWidth="600px" minWidth="1" minHeight="1">
          <ModalHeader>
            <ModalTitle variant="xlBold">{title}</ModalTitle>
            {canCloseModal && <ModalCloseButton onClick={closeModal} />}
          </ModalHeader>
          <ModalBody>
            <Grid gap={`${theme.space.s}px`}>
              {!wantsNewAddress && (
                <>
                  {modalOpenReason === ModalOpenReason.NormalizedError && (
                    <Alert
                      header="Not found, please double-check your address."
                      icon
                      variant="warning"
                      data-testid="address-not-found-alert"
                    />
                  )}
                  {modalOpenReason === ModalOpenReason.UnitNeeded && (
                    <Alert
                      header="This address requires a unit number."
                      icon
                      variant="warning"
                      data-testid="unit-number-required-alert"
                    />
                  )}
                </>
              )}
              <Text variant="m">{subTitle}</Text>
              <Box>
                <Text variant="mBold" paddingBottom="xs">
                  Street address
                </Text>
                <Input
                  label="Street address"
                  name="street"
                  value={addressForm.values.street}
                  onChange={addressForm.handleChange}
                  error={addressForm.errors.street as string}
                />
              </Box>
              <Box>
                <Text variant="mBold" paddingBottom="xs">
                  Street address line 2
                </Text>
                <RadioList
                  name="line2Type"
                  direction="horizontal"
                  distributeEqually
                  onChange={(value) => {
                    addressForm.setFieldValue('line2Type', value);
                    if (value === Line2Type.NONE) {
                      addressForm.setFieldValue('unit', null);
                    }
                  }}
                  options={addressLine2Options}
                  value={addressForm.values.line2Type}
                  variant="standard"
                  controlSize="s"
                />
                {showLine2 && (
                  <Box style={{ marginBottom: `${theme.space.s}px` }}>
                    <Input
                      label={capitalize(addressForm.values.line2Type)}
                      name="unit"
                      value={addressForm.values.unit}
                      onChange={addressForm.handleChange}
                      error={addressForm.errors.unit as string}
                    />
                  </Box>
                )}
              </Box>
              <Grid gap={`${theme.space.s}px`} gridAutoFlow="column">
                <Box>
                  <Input
                    name="city"
                    label="City"
                    onChange={addressForm.handleChange}
                    value={addressForm.values.city}
                    error={addressForm.errors.city as string}
                  />
                </Box>
                <Box>
                  <DropDownSelect
                    name="state"
                    label="State"
                    options={stateList}
                    onChange={addressForm.handleChange}
                    value={addressForm.values.state}
                    error={addressForm.errors.state as string}
                  />
                </Box>
              </Grid>
              <Grid gap={`${theme.space.s}px`} gridAutoFlow="column">
                <Box>
                  <Input
                    name="postalCode"
                    label="Zip code"
                    onChange={(e) => handlePostalCodeChange(e)}
                    value={addressForm.values.postalCode}
                    error={addressForm.errors.postalCode as string}
                  />
                </Box>
              </Grid>
            </Grid>
          </ModalBody>
          <ModalFooter flexDirection="column">
            {canCloseModal && (
              <ContinueContainer>
                <Button
                  isFluidWidth
                  variant="secondary"
                  type="button"
                  onClick={closeModal}
                  size="l"
                >
                  Cancel
                </Button>
                <SubmitButton
                  isFluidWidth
                  onClick={handleContinueButtonClick}
                  disabled={hasFormErrors}
                />
              </ContinueContainer>
            )}
            {!canCloseModal && (
              <Box display="flex" style={{ flexDirection: 'column' }}>
                <SubmitButton
                  onClick={handleContinueButtonClick}
                  disabled={hasFormErrors}
                />
              </Box>
            )}
            {!canCloseModal && (
              <HintsContainer>
                <Text variant="sBold">Helpful hints</Text>
                <ul>
                  <Hint>Double check your street address for typos.</Hint>
                  <Hint>
                    Spell out Circle, Court, Street, Road in your street
                    address.
                  </Hint>
                  <Hint>Check your city and state.</Hint>
                  <Hint>
                    Use the prefix (Apt - Unit) or the pound sign (#).
                  </Hint>
                  <Hint>
                    Give it another try and we will verify with the Post Office
                    records.
                  </Hint>
                </ul>
                <Text variant="sBold">
                  If you continue to have issues please call to speak with an
                  agent.
                </Text>
                <CallRailPhone text="Call ###" />
              </HintsContainer>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}
