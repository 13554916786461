import styled from '@emotion/styled';
import React, { useEffect, useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  TextLink,
  Button,
} from '@updater/ui-uds';
import { ShopType } from 'types';
import { useStore, useAddress, useNormalizedAddressQuery } from 'hooks';
import { useTracking } from 'react-tracking';
import { MicrositeContext } from 'context';
import { useShallow } from 'zustand/react/shallow';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const ServiceMessage = styled(Text)`
  max-width: 500px;
  text-align: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  button {
    margin: 0 10px;
  }
`;

export function CommercialAddressModal() {
  const micrositeConfig = useContext(MicrositeContext);
  const { commercialHomepage, shopType } = micrositeConfig;

  const hasCommercialHomepage = commercialHomepage !== undefined;
  const address = useAddress();
  const normalizedAddressResult = useNormalizedAddressQuery(address);

  const [
    wantsNewAddress,
    dismissCommercial,
    setWantsNewAddress,
    setDismissCommercial,
  ] = useStore(
    useShallow((store) => [
      store.wantsNewAddress,
      store.dismissCommercial,
      store.setWantsNewAddress,
      store.setDismissCommercial,
    ])
  );

  const hasCommercialAddress =
    !normalizedAddressResult.loading &&
    normalizedAddressResult.data?.normalizedAddress?.normalizedAddress
      ?.classification === 'COMMERCIAL';

  const modalIsOpen =
    hasCommercialAddress && !wantsNewAddress && !dismissCommercial;

  const { trackEvent } = useTracking({
    object: 'commercial_address_modal',
  });

  useEffect(() => {
    if (modalIsOpen) {
      trackEvent({
        verb: 'viewed',
      });
    }
  }, [modalIsOpen, trackEvent]);

  function closeModal() {
    // setNotServieableModal(false);
  }
  function continueToOffers() {
    setDismissCommercial(true);
  }
  function changeAddress() {
    setWantsNewAddress(true);
  }
  function goToCommercial() {
    trackEvent({
      object: 'commercial_homepage_button',
      verb: 'clicked',
    });
    window.location.assign(commercialHomepage);
  }

  let serviceTitle;
  let serviceMessage;
  const buttonLinks = [];
  if (modalIsOpen) {
    if (
      shopType === ShopType.SINGLE_PROVIDER &&
      hasCommercialHomepage &&
      'mainProvider' in micrositeConfig
    ) {
      const { mainProvider } = micrositeConfig;
      const { displayName } = mainProvider;
      // If this single provider has a commercial/business homepage,
      // link to it and give the user the option to change address as well.
      serviceTitle = `${displayName} has special offers available for businesses!`;
      serviceMessage = (
        <ServiceMessage variant="m" marginBottom="l">
          It looks like you entered a commercial or business address. Click the
          link below to browse {displayName} business offers, or{' '}
          <TextLink onClick={changeAddress}>click here</TextLink> to enter a
          different address.
        </ServiceMessage>
      );
      const button = (
        <Button
          onClick={goToCommercial}
          marginTop="l"
          dataCy="commercial-home-button"
          data-testid="commercial-home-button"
          size="l"
        >
          Browse Business Offers
        </Button>
      );
      buttonLinks.push(button);
    } else {
      serviceTitle = `You entered a commercial or business address.`;
      serviceMessage = (
        <ServiceMessage variant="m" marginBottom="l">
          You may not see offers from providers at non-residential addresses. If
          this was intentional, click Continue to Offers to qualify for
          available offers. If you&apos;d like to enter a new address, click
          Change Address.
        </ServiceMessage>
      );
      const button1 = (
        <Button
          onClick={continueToOffers}
          variant="secondary"
          marginTop="l"
          dataCy="commercial-continue-button"
          size="l"
        >
          Continue to Offers
        </Button>
      );
      const button2 = (
        <Button
          onClick={changeAddress}
          marginTop="l"
          dataCy="commercial-address-button"
          size="l"
        >
          Change Address
        </Button>
      );
      buttonLinks.push(button1, button2);
    }
  }

  return (
    <Modal isOpen={modalIsOpen} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="736" minWidth="1" minHeight="1">
        <ModalBody padding="l" marginBottom="xxxxl">
          <Container>
            <Text
              as="h1"
              variant={['mBold', null, 'xlBold', null, null]}
              marginBottom="l"
              textAlign="center"
            >
              {serviceTitle}
            </Text>
            {serviceMessage}
            <ButtonContainer>
              {buttonLinks.map((button, idx) => {
                const key = `button-${idx}`;
                return <React.Fragment key={key}>{button}</React.Fragment>;
              })}
            </ButtonContainer>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
