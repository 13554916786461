import React from 'react';
import styled from '@emotion/styled';

import { Box, BoxProps, Palette, Text } from '@updater/ui-uds';
import { CallRailPhone } from 'components';

export type SupportInfoLayout = 'narrow' | 'wide';

interface SupportInformationProps extends BoxProps {
  layout?: SupportInfoLayout;
}

const SupportBox: React.FC<BoxProps> = styled(Box)`
  border: 1px solid ${Palette.gray80};
`;

const CallWrapper = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => `${theme.colors?.cta}`};
`;
export function SupportInformation({
  mt,
  layout = 'wide',
}: SupportInformationProps) {
  const monFriHours = 'Mon-Fri 7am to 1am EST';
  const satSunHours = 'Sat-Sun 7am to 11pm EST';
  return (
    <SupportBox
      width={['100%', null, null, null, '476px']}
      maxWidth="708px"
      borderRadius="xs"
      p="s"
      mt={mt}
      mb="m"
      data-testid="support-info-box"
    >
      <Text
        data-testid="support-text"
        variant="xsBold"
        color={Palette.gray30}
        mb="xxs"
      >
        Need help placing your order?
      </Text>
      <CallWrapper variant="m" mb="xxs" data-testid="phone-number">
        <CallRailPhone text="Call ###" />
      </CallWrapper>
      {layout === 'wide' && (
        <Text
          variant="xs"
          color={Palette.gray30}
          data-testid="large-layout-hours"
        >
          {monFriHours} & {satSunHours}
        </Text>
      )}
      {layout === 'narrow' && (
        <>
          <Text
            variant="xs"
            color={Palette.gray30}
            data-testid="small-layout-hours-1"
          >
            {monFriHours}
          </Text>
          <Text
            variant="xs"
            color={Palette.gray30}
            data-testid="small-layout-hours-2"
          >
            {satSunHours}
          </Text>
        </>
      )}
    </SupportBox>
  );
}
