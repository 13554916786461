import styled from '@emotion/styled';
import { useContext } from 'react';
import { MicrositeContext } from 'context';
import { CallRailPhone, CallRailComponentType } from 'components';
import { Events } from 'services';
import { Phone } from '@phosphor-icons/react';
import { PageContent } from '../PageContent';
import { BrandLogo } from './BrandLogo';
import { UpdaterLogo } from './UpdaterLogo';
import { Container } from './Container';
import { Nav } from './Nav';
import { useRouter } from 'next/router';

const SmallText = styled.small`
  width: 100%;
  font-size: 12px;
`;

const MobileCallContainer = styled.div`
  display: block;
  grid-area: call;

  ${({ theme }) => theme.mediaQueries.m} {
    display: none;
  }
`;

const DesktopCallContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQueries.m} {
    display: flex;
    grid-area: call;
    justify-content: center;
    padding: 7px 8px 7px 8px;
    flex-direction: column;
    align-items: center;
    text-align: right;
  }
`;

const LogoContainer = styled.div`
  grid-area: logo;
  display: flex;
  align-items: center;
`;

export const Header = () => {
  const micrositeConfig = useContext(MicrositeContext);
  const updaterCobranding =
    micrositeConfig && micrositeConfig.hasUpdaterCobranding;
  const router = useRouter();
  const isLeadModeConfirmationPage = Boolean(
    router.pathname === '/confirmation'
  );

  return (
    <Container>
      <PageContent>
        <Nav>
          <LogoContainer>
            <BrandLogo />
            {updaterCobranding && <UpdaterLogo />}
          </LogoContainer>
          <DesktopCallContainer>
            <CallRailPhone
              variant="xl"
              text="###"
              clickEventName={Events.CALL_BUTTON_DESKTOP_HEADER}
              textColor="#fff"
              icon={<Phone />}
              isLeadMode={isLeadModeConfirmationPage}
            />
          </DesktopCallContainer>
          <MobileCallContainer>
            <CallRailPhone
              buttonSize="s"
              componentType={CallRailComponentType.BUTTON}
              text="Call now!"
              icon={<Phone weight="fill" />}
              clickEventName={Events.CALL_BUTTON_MOBILE_HEADER}
              isLeadMode={isLeadModeConfirmationPage}
            />
          </MobileCallContainer>
        </Nav>
      </PageContent>
    </Container>
  );
};
