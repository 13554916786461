import Link from 'next/link';
import getConfig from 'next/config';
import styled from '@emotion/styled';

const UpdaterContainer = styled.div`
  border-style: solid;
  border-color: ${({ theme }) => theme.customTheme.colors.headerBorder};
  border-width: 0 0 0 1px;
  margin-left: 10px;
  padding-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const UpdaterLogo = () => {
  const { publicRuntimeConfig } = getConfig();
  const { basePath } = publicRuntimeConfig;
  const path = `${basePath}/updater-logo.svg`;
  return (
    <UpdaterContainer data-testid="updater-logo">
      <Link href="https://updater.com">
        <img src={path} alt="Updater" height="35" width="137" />
      </Link>
    </UpdaterContainer>
  );
};

export default UpdaterLogo;
