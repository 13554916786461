import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@updater/ui-uds';
import { Spinner } from '@phosphor-icons/react';
import { useAsyncCheckoutUrl, useStore } from 'hooks';
import { useShallow } from 'zustand/react/shallow';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const SpinnerContainer = styled.div`
  margin-top: 30px;
  height: 64px;
`;

export function AsyncLoadingModal() {
  const router = useRouter();
  const asyncCheckoutUrl = useAsyncCheckoutUrl();
  const [asyncChosenOffer, setAsyncChosenOffer] = useStore(
    useShallow((store) => [store.asyncChosenOffer, store.setAsyncChosenOffer])
  );

  // The modal is open if we have an offer in local storage
  const showModal = asyncChosenOffer !== undefined;

  if (asyncCheckoutUrl !== undefined) {
    setAsyncChosenOffer(undefined);
    // This is a bit hacky -- the async checkout can fail if we
    // don't get the right data back from the DC API.  If the url
    // begins with an https, we know that we're sending the user
    // to the guest-buyflow app.  If it doesn't begin with https,
    // it's a route in provider app and we should use next.router.
    // This will cease to be necessary once we deprecate the separate
    // guest-buyflow app and move the async buyflow into provider app.
    console.log(`navigating to ${asyncCheckoutUrl}`);
    if (asyncCheckoutUrl.substr(0, 5) === 'https') {
      window.location.assign(asyncCheckoutUrl);
    } else {
      router.push(asyncCheckoutUrl);
    }
  }

  function closeModal() {}

  return (
    <Modal isOpen={showModal} setIsOpen={closeModal}>
      <ModalOverlay cursor="default" />
      <ModalContent maxWidth="736" minWidth="1" minHeight="1">
        <ModalBody padding="l">
          <Container>
            <Text
              as="h1"
              variant={['mBold', null, 'xlBold', null, null]}
              marginBottom="l"
              textAlign="center"
            >
              Great choice!
            </Text>
            <Text variant="m">
              Preparing your shopping cart for online checkout...
            </Text>
            <SpinnerContainer>
              <Spinner
                data-testid="loader"
                size={64}
                color="#333"
                weight="fill"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="2s"
                  from="0 0 0"
                  to="360 0 0"
                  repeatCount="indefinite"
                />
              </Spinner>
            </SpinnerContainer>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
