import { SuperSchema } from '@updater/ui-informant';

export const activitiesSchema: SuperSchema = {
  if: {
    properties: {
      serviceLines: {
        contains: { const: 'internet' },
      },
    },
  },
  then: {
    type: 'object',
    properties: {
      activities: {
        type: 'array',
        minItems: 1,
        items: {
          type: 'string',
          oneOf: [
            {
              const: 'browsingTheWebAndEmail',
              title: 'Browsing the web & email',
            },
            {
              const: 'streamingTvShowAndMovies',
              title: 'Streaming TV shows & movies',
            },
            {
              const: 'onlineGaming',
              title: 'Online gaming',
            },
            {
              const: 'connectingSmartHomeDevices',
              title: 'Connecting smart home devices',
            },
          ],
        },
        ui: {
          component: 'checklist',
          wrapperComponent: 'CheckListWrapper',
          componentProps: {
            options: [
              {
                label: 'Browsing the web & email',
                value: 'browsingTheWebAndEmail',
                id: 'browsingTheWebAndEmail',
              },
              {
                label: 'Streaming TV shows & movies',
                value: 'streamingTvShowAndMovies',
                id: 'streamingTvShowAndMovies',
              },
              {
                label: 'Online gaming',
                value: 'onlineGaming',
                id: 'onlineGaming',
              },
              {
                label: 'Connecting smart home devices',
                value: 'connectingSmartHomeDevices',
                id: 'connectingSmartHomeDevices',
              },
            ],
          },
        },
        required: ['oneOf'],
      },
    },
    required: ['activities'],
  },
};
