import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.xl} {
    flex-direction: row;
  }
`;
