import { SuperSchema } from '@updater/ui-informant';

export const ServicesSchema: SuperSchema = {
  type: 'object',
  properties: {
    serviceLines: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
        oneOf: [
          {
            const: 'internet',
            title: 'Internet',
          },
          {
            const: 'tv',
            title: 'TV',
          },
        ],
      },
      ui: {
        component: 'checklist',
        wrapperComponent: 'CheckListWrapper',
        componentProps: {
          options: [
            {
              label: 'Internet',
              id: 'internet',
              value: 'internet',
            },
            {
              label: 'TV',
              id: 'tv',
              value: 'tv',
            },
          ],
        },
      },
      required: ['oneOf'],
    },
  },
  required: ['serviceLines'],
};
