import styled from '@emotion/styled';

export const HintsContainer = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  padding: 22px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;
