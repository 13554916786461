import React from 'react';

import {
  Form,
  FormProvider,
  SuperSchema,
  useFormContext,
} from '@updater/ui-informant';
import { Box, Button, Flex } from '@updater/ui-uds';
import router from 'next/router';
import { useQual } from 'hooks';

const leadModeSchema: SuperSchema = {
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 2,
      maxLength: 255,
      ui: {
        label: 'First name',
        inputType: 'text',
        messages: {
          required: 'First name is required!',
          minLength:
            'The first name should have a minimal length of 2 characters',
        },
        width: [1, 1, 0.5],
      },
    },
    lastName: {
      type: 'string',
      minLength: 2,
      maxLength: 255,
      ui: {
        label: 'Last name',
        width: [1, 1, 0.5],
        messages: {
          required: 'Last name is required!',
          minLength:
            'The last name should have a minimal length of 2 characters',
        },
      },
    },
    email: {
      title: 'email',
      type: 'string',
      pattern:
        '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      ui: {
        label: 'Email',
        messages: {
          pattern: 'Please enter a valid email address',
        },
        width: [1, 1, 0.5],
        validate: 'verifyEmailAvailability',
        validateOnChange: false,
      },
    },
    phone: {
      title: 'Phone Number',
      type: 'string',
      pattern:
        // The phone input value will give back digits and prefix a 1
        // but schema needs to accept either 9 or 10 digits
        // to handle the prefilled value
        '^1?[2-9][0-9]{9}$',
      ui: {
        component: 'phone',
        label: 'Phone Number',
        messages: {
          required: 'Phone number is required',
          pattern: 'Please enter a valid phone number',
        },
        width: [1, 1, 0.5],
      },
    },
  },
  required: ['phone'],
};

function FormActionButtons() {
  const context = useFormContext();
  return (
    <Flex
      width="100%"
      maxWidth="870px"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-between"
      marginBottom="s"
    >
      <Box
        width={['100%', null, '300px', null, null]}
        paddingRight={['none', null, 's', null, null]}
      >
        <Button
          type="button"
          my="xs"
          size="l"
          isFluidWidth
          variant="secondary"
          onClick={() => router.back()}
        >
          Back to Services
        </Button>
      </Box>
      <Box
        width={['100%', null, '300px', null, null]}
        paddingLeft={['none', null, 's', null, null]}
      >
        <Button
          my="xs"
          data-cy="submitButton"
          data-testid="submitButton"
          isFluidWidth
          size="l"
          disabled={!context.isValid || context.isSubmitting}
        >
          Submit
        </Button>
      </Box>
    </Flex>
  );
}

export function LeadModeForm({ handleChange, onSubmit }) {
  const {
    userFirstName: firstName,
    userLastName: lastName,
    userEmail: email,
    userPhoneNumber: phone,
  } = useQual();
  return (
    <FormProvider
      data-cy="leadModeForm"
      schema={leadModeSchema}
      onChange={handleChange}
      onSubmit={onSubmit}
      submitComponent={<FormActionButtons />}
      initialValues={{ firstName, lastName, email, phone }}
    >
      <Form />
    </FormProvider>
  );
}
