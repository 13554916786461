import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  ATT,
  DTV,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
  //Earthlink,
} from 'config/providers';
import { DirecTVTheme } from 'config/themes';

export const DirecTVSpecials: SingleProviderConfig = {
  name: 'DirecTVSpecials',
  micrositeCode: 'directv',
  mainProvider: DTV,
  shopType: ShopType.SINGLE_PROVIDER,
  // shopType: ShopType.SYNTHETIC,
  domain: 'directvspecials.com',
  homepage: 'https://directvspecials.com',
  title: 'DirecTV Authorized Dealer',
  phoneNumber: '855-714-5607',
  leadModePhoneNumber: '855-587-7469',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: DirecTVTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'DTV',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  updaterCrossSellActive: false,
  crossSellProviders: [
    ATT,
    Verizon,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    Windstream,
    CincinnatiBell,
    //Earthlink,
  ],
};
