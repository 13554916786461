import {
  SingleProviderConfig,
  ShopType,
  CheckoutType,
  CrossSellType,
} from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import { WindstreamTheme } from 'config/themes';
import {
  ATT,
  Brightspeed,
  BrightspeedV2,
  CenturyLink,
  Frontier,
  Windstream,
  CincinnatiBell,
  Verizon,
  Comcast,
  Cox,
  Spectrum,
  Optimum,
  Wave,
  Grande,
  RCN,
  WOW,
  TDS,
  BuckeyeBroadband,
  Mediacom,
} from 'config/providers';

export const OrderWindstream: SingleProviderConfig = {
  name: 'OrderWindstream',
  micrositeCode: 'Windstream',
  mainProvider: Windstream,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'orderwindstream.com',
  homepage: 'https://orderwindstream.com',
  title: 'Windstream Authorized Dealer',
  phoneNumber: '855-446-1939',
  leadModePhoneNumber: '800-972-6964',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: WindstreamTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'Windstream',
  noOffersRetry: 2,
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.orderwindstream.com',
  },
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    ATT,
    Verizon,
    Brightspeed,
    BrightspeedV2,
    CenturyLink,
    Frontier,
    CincinnatiBell,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    Comcast,
    Cox,
    Spectrum,
    Optimum,
    Wave,
    Grande,
    RCN,
    WOW,
    TDS,
    BuckeyeBroadband,
    Mediacom,
  ],
};
