import { SingleProviderConfig, ShopType, CheckoutType } from 'types';
import { allLeadMode, getDefaultCrossSellAction } from 'config';
import {
  ATT,
  CenturyLink,
  Earthlink,
  Frontier,
  Windstream,
  CincinnatiBell,
} from 'config/providers';
import { ATTTheme } from 'config/themes';

// Origin - https://updater.atlassian.net/browse/TVI-1047
export const AdActionATTPartner: SingleProviderConfig = {
  name: 'AdActionATTPartner',
  micrositeCode: 'adactionattpartner',
  mainProvider: ATT,
  shopType: ShopType.SINGLE_PROVIDER,
  domain: 'attspecial.com',
  homepage: 'https://attspecial.com',
  title: 'AT&T Authorized Dealer',
  phoneNumber: '844-806-6187',
  leadModePhoneNumber: '844-820-1562',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: ATTTheme,
  needsDcSession: false,
  isIFramed: false,
  source: 'ATT',
  noOffersRetry: 2,
  getCrossSellAction: getDefaultCrossSellAction,
  getCheckoutType: allLeadMode,
  crossSellProviders: [
    CenturyLink,
    CincinnatiBell,
    Earthlink,
    Frontier,
    Windstream,
  ],
  updaterCrossSellActive: true,
  updaterCrossSellProviders: [
    ATT,
    CenturyLink,
    CincinnatiBell,
    Earthlink,
    Frontier,
    Windstream,
  ],
  uxBuyflowUrls: {
    development: 'https://buyflow.staging.bridgevine.io',
    staging: 'https://buyflow.staging.bridgevine.io',
    qa: 'https://buyflow.qa.bridgevine.io',
    production: 'https://cart.attspecial.com',
  },
};
