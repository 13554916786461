import { Box, Flex, Text, TextLink } from '@updater/ui-uds';
import { useFormContext } from '@updater/ui-informant';
import { QuestionForm } from 'types';
import { Events, useTracking } from 'services';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTracking as useReactTracking } from 'react-tracking';
import { useQual } from 'hooks/useQual';
import { useStore } from 'hooks/useStore';
import { useShallow } from 'zustand/react/shallow';

const FORMATTED_SERVICE_NAME = {
  internet: 'internet',
  tv: 'TV',
  homePhone: 'home phone',
};

export function SkipLinkWithDisclaimer() {
  const router = useRouter();
  const { userFirstName, userLastName, zip, city, state, apartment, street } =
    useQual();

  const { values } = useFormContext<QuestionForm>();
  const tracking = useTracking();
  const { trackEvent: reactTrackEvent } = useReactTracking();
  const [setAcceptedUpdaterTerms] = useStore(
    useShallow((store) => [store.setAcceptedUpdaterTerms])
  );

  const formattedService = useMemo(
    () =>
      values.serviceLines
        .map((service) => FORMATTED_SERVICE_NAME[service])
        .join(', '),
    [values.serviceLines]
  );

  const onSkipLinkClick = useCallback(() => {
    // https://updater.atlassian.net/jira/software/c/projects/TVI/boards/541?assignee=60bbd7523aa6050068a5fca0&selectedIssue=TVI-244
    reactTrackEvent({
      verb: 'accepted',
      object: 'privacy_and_terms_consent',
      details: {
        user_address: {
          street,
          unit: apartment,
          city,
          state,
          zip,
        },
        first_name: userFirstName,
        last_name: userLastName,
      },
    });
    tracking.trackEvent(Events.SKIP_QUESTIONS, 'clicked');
    setAcceptedUpdaterTerms(true);
    router.push('/plans');
    return true;
  }, [reactTrackEvent, tracking.trackEvent, setAcceptedUpdaterTerms]);

  return (
    <Box>
      <Flex mb="s">
        <Text variant="m">
          Skip and show me{' '}
          <TextLink
            data-testid="showPlansLink"
            showTextDecoration
            onClick={onSkipLinkClick}
          >
            all {formattedService} offers
          </TextLink>{' '}
          in my area.
        </Text>
      </Flex>
      <Flex mb="s">
        <Text variant="xs">
          By clicking "Continue" or "all {formattedService} offers", you agree
          to the{' '}
          <TextLink
            data-testid="termsAndPrivacyLink"
            showTextDecoration
            href="https://updater.com/uhs-terms"
          >
            Updater terms of service and privacy policy
          </TextLink>
          .
        </Text>
      </Flex>
    </Box>
  );
}
