import { SuperSchema } from '@updater/ui-informant';
import * as allPageSchemas from './pages';

const { ServicesSchema, ...pageSchemas } = allPageSchemas;

export const schema: SuperSchema = {
  // The first page is inserted manually
  ...ServicesSchema,

  // all others are mixed in
  allOf: Object.values(pageSchemas).map((pageSchema) => {
    const schemaHasConditional =
      pageSchema.if ||
      pageSchema.not ||
      pageSchema.then ||
      pageSchema.else ||
      pageSchema.allOf ||
      pageSchema.anyOf ||
      pageSchema.oneOf;

    if (schemaHasConditional) {
      return pageSchema;
    }
    return {
      if: true,
      then: pageSchema,
    };
  }),
};
