import styled from '@emotion/styled';
import { Button } from '@updater/ui-uds';

export const ContinueButton = styled(Button)`
  width: 30;
  border-radius: 16px;
  font-weight: 400;
  height: 64px;
  align-self: end;
`;
